/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import {
    Button, Checkbox, Table, Icon, Tooltip
} from "antd";
import "../App.css";
import LangContext from "../contextProvider/LangContext";
import GenericModal from "./GenericModal";
import ModalCreateEditItemPrices from "./ModalCreateEditItemPrices";
import ResponsiveTable from "./ResponsiveTable";

class DocumentCreationItemsDiscrepancy extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalItemsDiscrepancyChecked: [],
            modalItemsDiscrepancyCheckedDefault: [],

            modalPricesView: {},

            modalBonusData: null,
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    stateCheckedWhenDefaultCheck = () => {
        let c = [...this.state.modalItemsDiscrepancyChecked];
        this.state.modalItemsDiscrepancyCheckedDefault.forEach(x => {
            if (c.indexOf(x) === -1) c = [...c, x]
        })

        this.setState({ modalItemsDiscrepancyChecked: c })
    }

    updateCostPrices = () => {
        const { supplierCode } = this.props.data.document2;
        let data = this.props.modalItemsDiscrepancyData.filter(f => this.state.modalItemsDiscrepancyChecked.indexOf(f.index) > -1);

        if (data.length) {

            let dataSend = "itemCode\fsupplier\fcost\fisDefault\fquantity" +
                "\f_differenceBonusData\f_baseCost\f_dscnt1\f_dscnt2\f_dscnt3\f_bonusQty\f_qtyForBonusQty\f_afterBonuses";

            data.forEach(x => {
                let isDefault = this.state.modalItemsDiscrepancyCheckedDefault.indexOf(x.index) > -1 ? '1' : '0';
                // let _baseCost = this.baseCalc(x.docCost, x._dscnt1, x._dscnt2, x._dscnt3).toFixed(2);
                let _afterBonuses = this.afterCalc(x.docCost, x._bonusQty, x._qtyForBonusQty).toFixed(2);

                dataSend += "\r" + x.itemCode + "\f" +
                    supplierCode + "\f" +
                    x.docCost + "\f" +
                    isDefault + "\f" +
                    x.quantity + "\f" +
                    x._differenceBonusData + "\f" +
                    x._baseCost + "\f" +
                    x._dscnt1 + "\f" +
                    x._dscnt2 + "\f" +
                    x._dscnt3 + "\f" +
                    x._bonusQty + "\f" +
                    x._qtyForBonusQty + "\f" +
                    _afterBonuses;
            })
            this.props.sendAPI("update_cost_price_from_doc", dataSend, this.afterUpdateCostPrices)
        } else {
            this.afterUpdateCostPrices();
        }
    }

    afterUpdateCostPrices = () => {
        this.setState({
            modalItemsDiscrepancyChecked: [],
            modalItemsDiscrepancyCheckedDefault: [],
        }, this.props.afterUpdateCostPrices)
    }

    getCheckboxCol = (stateName, titleText) => {
        let d = this.props.modalItemsDiscrepancyData;
        let checkedList = this.state[stateName];

        return {
            title: (<span>
                <div>{this.getText(titleText)}</div>
                <Checkbox
                    checked={checkedList.length === d.length}
                    onChange={(e) => {
                        this.setState({ [stateName]: e.target.checked ? d.map(x => x.index) : [] }, this.stateCheckedWhenDefaultCheck)
                    }} />
            </span>),
            width: '8%',
            render: (text, record) => {
                return (<Checkbox
                    checked={checkedList.indexOf(record.index) > -1}
                    onChange={(e) => {
                        this.setState({ [stateName]: e.target.checked ? [...checkedList, record.index] : checkedList.filter(f => f !== record.index) }, this.stateCheckedWhenDefaultCheck)
                    }} />)
            }
        }
    }

    getPricesIcon = (text, record) => {
        return (<Icon type="eye" onClick={() => {

            const { itemCode, defaultSuppId, defaultSuppCost, docCost, mTaxTypeId, index } = record;
            const { supplierCode } = this.props.data.document2;

            let costPrice = this.state.modalItemsDiscrepancyCheckedDefault.indexOf(index) > -1
                || (this.state.modalItemsDiscrepancyChecked.indexOf(index) > -1 && defaultSuppId === supplierCode) ?
                docCost : defaultSuppCost;

            this.setState({ modalPricesView: { costPrice, mTaxTypeId } }, () => {
                this.props.uiActions.showModal('modalCreateEditItemPrices', { itemId: itemCode });
            })

        }} />)
    }

    // baseCalc = (sum, d1, d2, d3) => {
    //     let res = parseFloat(sum);
    //     if (d1) res = res * (100 + parseFloat(d1)) / 100;
    //     if (d2) res = res * (100 + parseFloat(d2)) / 100;
    //     if (d3) res = res * (100 + parseFloat(d3)) / 100;
    //     return res ?? 0;
    // }

    afterCalc = (sum, b, q) => {
        let res = parseFloat(sum);
        if (b && q) res = res * parseFloat(q) / (parseFloat(q) + parseFloat(b))
        return res ?? 0;
    }

    docCostRender = (text, record) => {
        if (record._differenceBonusData == '1') {
            return (<div>
                {text}
                <Tooltip title={this.getText(19304)}>
                    <Icon type="setting" style={{ marginRight: 10 }} onClick={() => {
                        const {
                            defaultSuppCost, dbCost, docCost,
                            _baseCost, _dscnt1, _dscnt2, _dscnt3, _bonusQty, _qtyForBonusQty,
                            _supp_baseCost, _supp_dscnt1, _supp_dscnt2, _supp_dscnt3, _supp_bonusQty, _supp_qtyForBonusQty,
                            _suppDefault_baseCost, _suppDefault_dscnt1, _suppDefault_dscnt2, _suppDefault_dscnt3, _suppDefault_bonusQty, _suppDefault_qtyForBonusQty,
                        } = record;


                        // let _baseCost = this.baseCalc(docCost, _dscnt1, _dscnt2, _dscnt3).toFixed(2);
                        // let _supp_baseCost = this.baseCalc(dbCost, _supp_dscnt1, _supp_dscnt2, _supp_dscnt3).toFixed(2);
                        // let _suppDefault_baseCost = this.baseCalc(defaultSuppCost, _suppDefault_dscnt1, _suppDefault_dscnt2, _suppDefault_dscnt3).toFixed(2);
                        let _afterBonuses = this.afterCalc(docCost, _bonusQty, _qtyForBonusQty).toFixed(2);
                        let _afterBonusesSupp = this.afterCalc(dbCost, _supp_bonusQty, _supp_qtyForBonusQty).toFixed(2);
                        let _afterBonusesDefSupp = this.afterCalc(defaultSuppCost, _suppDefault_bonusQty, _suppDefault_qtyForBonusQty).toFixed(2);

                        let modalBonusData = [
                            { desc: this.getText(19305), inSupp: _supp_baseCost, inDefSupp: _suppDefault_baseCost, inDoc: _baseCost },
                            { desc: this.getText(19306), inSupp: _supp_dscnt1, inDefSupp: _suppDefault_dscnt1, inDoc: _dscnt1 },
                            { desc: this.getText(19307), inSupp: _supp_dscnt2, inDefSupp: _suppDefault_dscnt2, inDoc: _dscnt2 },
                            { desc: this.getText(19308), inSupp: _supp_dscnt3, inDefSupp: _suppDefault_dscnt3, inDoc: _dscnt3 },
                            { desc: this.getText(19309), inSupp: dbCost, inDefSupp: defaultSuppCost, inDoc: docCost },
                            { desc: this.getText(19310), inSupp: _supp_bonusQty, inDefSupp: _suppDefault_bonusQty, inDoc: _bonusQty },
                            { desc: this.getText(19311), inSupp: _supp_qtyForBonusQty, inDefSupp: _suppDefault_qtyForBonusQty, inDoc: _qtyForBonusQty },
                            { desc: this.getText(19312), inSupp: _afterBonusesSupp, inDefSupp: _afterBonusesDefSupp, inDoc: _afterBonuses },
                        ]
                        this.setState({ modalBonusData })
                    }} />
                </Tooltip>

            </div>)
        } else {
            return text
        }
    }

    bonusDataRender = (text, record) => {
        const { inDoc, inSupp, inDefSupp } = record;
        if ((inSupp && inDoc != inSupp) || (!inSupp && inDoc != inDefSupp)) {
            return (<span style={{ color: "red" }}>{text}</span>)
        } else {
            return text
        }
    }

    componentDidUpdate(prev) {
        const { modalItemsDiscrepancy, modalItemsDiscrepancyData, markChangeOnCost } = this.props;
        if (!prev.modalItemsDiscrepancy && modalItemsDiscrepancy && markChangeOnCost) {
            let fullChecked = modalItemsDiscrepancyData.map(x => x.index);
            this.setState({
                modalItemsDiscrepancyChecked: fullChecked,
                modalItemsDiscrepancyCheckedDefault: fullChecked
            }, this.stateCheckedWhenDefaultCheck)
        }
    }

    render() {
        let d = this.props.modalItemsDiscrepancyData;

        let itemsDiscrepancyColumns = [
            { key: 'itemCode', dataIndex: 'itemCode', title: this.getText(16191), width: '9%', render: t => t },
            { key: 'itemName', dataIndex: 'itemName', title: this.getText(16192), width: '25%', render: t => t },
            { key: 'defaultSuppName', dataIndex: 'defaultSuppName', title: this.getText(16205), width: '15%', render: t => t },
            { key: 'defaultSuppCost', dataIndex: 'defaultSuppCost', title: this.getText(16206), width: '9%', render: t => t },
            { key: 'dbCost', dataIndex: 'dbCost', title: this.getText(16193), width: '9%', render: t => t },
            { key: 'docCost', dataIndex: 'docCost', title: this.getText(16194), width: '9%', render: this.docCostRender },
            this.getCheckboxCol('modalItemsDiscrepancyChecked', 16207),
            this.getCheckboxCol('modalItemsDiscrepancyCheckedDefault', 16208),
            { title: this.getText(16244), width: '8%', render: this.getPricesIcon },
        ]


        return (<GenericModal
            visible={this.props.modalItemsDiscrepancy}
            onCancel={this.props.onCancel}
            // closable={false}
            title={this.getText(16190)}
            footer={[
                <Button type={'primary'} onClick={this.updateCostPrices}>{this.getText(16188)}</Button>,
                <Button onClick={this.afterUpdateCostPrices}>{this.getText(16189)}</Button>,
                <Button onClick={this.props.onCancel}>{this.getText(19203)}</Button>
            ]}>
            <Table
                bordered
                dataSource={d}
                columns={itemsDiscrepancyColumns}
                scroll={{ y: window.innerHeight / 2 }}
                pagination={false}
            />
            <ModalCreateEditItemPrices
                {...this.props}
                isFromDoc
                isValidItemCode
                costPrice={this.state.modalPricesView.costPrice}
                mTaxTypeId={this.state.modalPricesView.mTaxTypeId}
                visible={this.props.ui.modalStack.includes('modalCreateEditItemPrices')}
                onCancel={() => {
                    let saveData = this.props.data.ITEM_PRICE_LIST_CREATE_EDIT.data;
                    let selectorData = this.props.data.GENERIC_SELECTOR_PRICE_LIST.dataset;
                    let noTaxText = '(לא כולל מע"מ)';

                    if (saveData.length) {
                        let dataSend = 'mPriceListId\fmCode\fmPriceVal\fmFixedProfit';
                        saveData.forEach(x => {
                            let y = x.split('\f');
                            let plIsNoTax = selectorData.find(f => f.code === y[1] && f.name.indexOf(noTaxText) > -1);

                            dataSend += '\r' + y[1] + '\f' + y[2] + '\f' + (plIsNoTax ? y[4] : y[3]) + '\f' + y[5];
                        });
                        this.props.sendAPI("create_edit_prices_from_doc", dataSend, () => {
                            this.setState({ modalPricesView: {} }, () => {
                                this.props.uiActions.hideModal('modalCreateEditItemPrices');
                                this.props.dataActions.setJson('ITEM_PRICE_LIST_CREATE_EDIT', { ...this.props.data.genericData })
                            });
                        })
                    } else {
                        this.setState({ modalPricesView: {} }, () => {
                            this.props.uiActions.hideModal('modalCreateEditItemPrices')
                        });
                    }

                }}
            />
            <GenericModal
                visible={this.state.modalBonusData != null}
                onCancel={() => { this.setState({ modalBonusData: null }) }}
                title={this.getText(19304)}
                width={600}
            >
                <ResponsiveTable
                    tableOnly
                    dataSource={this.state.modalBonusData ?? []}
                    columns={[
                        { title: this.getText(19313), key: "desc", dataIndex: "desc", width: "40%", render: this.bonusDataRender },
                        { title: this.getText(19314), key: "inSupp", dataIndex: "inSupp", width: "20%", render: this.bonusDataRender },
                        { title: this.getText(19315), key: "inDefSupp", dataIndex: "inDefSupp", width: "20%", render: this.bonusDataRender },
                        { title: this.getText(19316), key: "inDoc", dataIndex: "inDoc", width: "20%", render: this.bonusDataRender },
                    ]}
                    pagination={false}
                />

            </GenericModal>

        </GenericModal>)

    }

}
export default DocumentCreationItemsDiscrepancy;