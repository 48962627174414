import React, { Component, PureComponent, ReactDOM } from 'react';
import { Table, Input, InputNumber, Icon, Popconfirm, Tooltip, Button, Row, Col, Checkbox, Select, message, notification, Modal, Radio } from "antd";
import Jax from "../Jax/jax.es6.module";
import TableIDs from '../data/TableIDs.js';
import { Job, User, OutputType, ProcessType } from '../JmReact';

import styles from '../style/tableOptimized.module.css';
//import { conditionalExpression } from '@babel/types';
import SelectorItems from './SelectorItems';
import LangContext from "../contextProvider/LangContext";
import { encodeXml, decodeXml } from "../utils/utils.js"
import GenericModal from './GenericModal';
import ResponsiveTable from './ResponsiveTable';
import GenericTable from './GenericTable';
import GenericSelector from './GenericSelector';
import ModalBonusCulc from './ModalBonusCalc';
//import Documents from '../pages/documents/CreateDocument';

//, configByDocType, configObject

const NUM_OF_ROWS = 15;
const Search = Input.Search;
//const { confirm } = Modal;

const Option = Select.Option;

var timeoutSelectFocus = null;


class TableOptimized extends Component/*<Props, State>*/ {
    static defaultProps = {
        dataSource: [],
        editedRecord: {}
    }

    constructor(props/*: Props*/) {
        super(props);
        this.state = {}
        this.state.paging = {
            page: 1,
            numOfPages: ((this.props.dataSource.length) / NUM_OF_ROWS) + 1,
            rowsPerPage: NUM_OF_ROWS,
        }

        this.state.isCtrlB = false;
        this.state.buffer = "";
        this.state.isBufferFull = "";
        this.state.selectorItemsDataSource2 = [];
        this.state.modalItemProfit = null;
        // this.state.isSumFlag = false;

        this.id_lastPrices = "LAST_PRICES_FOR_CUST";

        if (props.data[this.id_lastPrices] === undefined) props.data[this.id_lastPrices] = { ...props.data.genericData }

        this.drag_id = null;

    }

    static contextType = LangContext;

    componentDidMount() {
        console.log("TableOptimized: mounted");
    }
    componentWillUnmount() { console.log("TableOptimized: un-mounted"); }

    getText = (id) => { return this.context.get(id) || '[' + id + ']' }

    calcNumOfPages() {
        let numOfPages = Math.ceil((this.props.dataSource.length) / NUM_OF_ROWS/*just to catch zero*/);
        return (numOfPages === 0) ? 1 : numOfPages;
    }
    rowCount() { return this.props.dataSource.length; }

    tryShowUOMSizesModal = (itemCode) => {
        // SizeID, SizeDesc, SizeUOMQty, ItemRuler1ID
        var dataSend = "_ItemCode\r" + itemCode;
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_UOM_ruler_by_itemCode", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", (ob) => {
            if (ob.data) {
                let len = (ob.data).split('\r').length;
                if (len > 1) {
                    this.props.uiActions.showModal(TableIDs.modalSelectorUOMQty, { ...this.props.ui.data, _itemCodeRuler: itemCode });
                } else {
                    // Do Nothing
                }
            } else {
                // DO Nothing
            }
        },
            e => e); // callback problem here 
    }

    calculateRecord = (fieldName, val, calcDscnts) => {
        // console.log("calculateRecord start:  ", fieldName, val);
        const {
            keyVal, isValid, itemCode, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, unitPriceNoTax, totalSumNoTax, taxPercentageValue, taxId, remarks,
            unitID, quantityInUnit, unitQuantity, unitDescription,
            costCurrencyId, costCurrencyRate, costCurrencySymbol, costCurrencyValue
        } = this.props.editedRecord;

        let record = { ...this.props.editedRecord }


        //{sizeID: "46", sizeDesc: "יחידה", sizeUOMQty: "1", itemRuler1ID: "16"}
        let res = (this.props.data['UOM_Ruler'] && this.props.data['UOM_Ruler'].data ? this.props.data['UOM_Ruler'].data : [])
            .find(i => {
                return (i.sizeID).toString() === val.toString()
            });
        let _quantityInUnit = (res && res.sizeUOMQty) ? res.sizeUOMQty : 1;
        let isTotalEdited = false;

        let discountPercentageValue = parseFloat(discountPercentage) ? parseFloat(discountPercentage) : 0;

        if (fieldName === 'totalSumNoTax') {
            if (parseFloat(totalSumNoTax) !== parseFloat(val)) {
                // console.log("calculateRecord middle:  ", parseFloat(totalSumNoTax),parseFloat(val));

                isTotalEdited = true;
            }
        }
        if (fieldName === 'totalSumWithTax') {
            if (parseFloat(totalSumWithTax).toFixed(2) != parseFloat(val).toFixed(2)) { isTotalEdited = true; }
        }

        if (fieldName === 'quantity') {
            let totalSumNoTax = parseFloat((val * unitPriceNoTax) * (1.00 - discountPercentageValue / 100));
            record.unitQuantity = Math.round(val / quantityInUnit);
            record.quantity = val; //record.unitPriceWithTax = unitPriceWithTax;
            record.totalSumNoTax = totalSumNoTax.toFixed(4);
            record.totalSumWithTax = parseFloat(totalSumNoTax * (1.00 + taxPercentageValue / 100)).toFixed(4);

        } else if (fieldName === 'unitPriceWithTax') {
            if (val >= 0 && parseFloat(unitPriceWithTax).toFixed(2) != parseFloat(val).toFixed(2)) {
                let totalSumWithTax = parseFloat((quantity * val) * (1.00 - discountPercentageValue / 100));
                record.unitPriceWithTax = val; //record.unitPriceWithTax = unitPriceWithTax;
                record.totalSumWithTax = totalSumWithTax.toFixed(4);
                record.unitPriceNoTax = parseFloat(val / (1.00 + taxPercentageValue / 100)).toFixed(4);
                record.totalSumNoTax = parseFloat(totalSumWithTax / (1.00 + taxPercentageValue / 100)).toFixed(4);
                if (costCurrencyId > 1) {
                    record.costCurrencyValue = parseFloat(record.unitPriceNoTax / costCurrencyRate).toFixed(4);
                }
                if (calcDscnts) {
                    record.bonusCalcData = {
                        mBaseCost: 0,
                        mSupDscnt1: 0,
                        mSupDscnt2: 0,
                        mSupDscnt3: 0,
                        mCostIncludeDscnt: 0,
                        mBonusQty: 0,
                        mQtyForBonusQty: 0,
                        mCostIncludeBonus: 0
                    }
                }
            }
        } else if (fieldName === 'discountPercentage') {
            if (val >= 0) {
                let totalSumWithTax = parseFloat((quantity * unitPriceWithTax) * (1.00 - val / 100));
                record.discountPercentage = val;
                record.totalSumWithTax = totalSumWithTax.toFixed(4);
                record.totalSumNoTax = parseFloat(totalSumWithTax / (1.00 + taxPercentageValue / 100)).toFixed(4);
            }
        } else if (fieldName === 'totalSumWithTax') {
            if (val >= 0 && parseFloat(totalSumWithTax).toFixed(2) != parseFloat(val).toFixed(2)) {
                record.totalSumWithTax = val;
                // let _discount = parseFloat((1.00 - (val / (quantity * unitPriceWithTax))) * 100).toFixed(2)
                // if (isTotalEdited === true) {
                //     //record.discountPercentage = parseFloat((1.00 - (val / (quantity * unitPriceWithTax))) * 100).toFixed(2);
                //     record.discountPercentage = isNaN(_discount) ? 0 : _discount;
                // }
                let totalNoTax = parseFloat(val / (1.00 + taxPercentageValue / 100))
                record.totalSumNoTax = totalNoTax.toFixed(4);
                record.discountPercentage = 0;
                record.unitPriceWithTax = parseFloat(val / quantity).toFixed(4);
                record.unitPriceNoTax = parseFloat(totalNoTax / quantity).toFixed(4);
            }
        } else if (fieldName === 'unitPriceNoTax') {
            if (val >= 0) {
                let totalSumNoTax = parseFloat((quantity * val) * (1.00 - discountPercentageValue / 100))
                record.unitPriceNoTax = val; //record.unitPriceWithTax = unitPriceWithTax;
                record.totalSumNoTax = totalSumNoTax.toFixed(4);
                record.unitPriceWithTax = parseFloat(val * (1.00 + taxPercentageValue / 100)).toFixed(4);
                record.totalSumWithTax = parseFloat(totalSumNoTax * (1.00 + taxPercentageValue / 100)).toFixed(4);
                if (costCurrencyId > 1) {
                    record.costCurrencyValue = parseFloat(val / costCurrencyRate).toFixed(4);
                }
                if (calcDscnts) {
                    record.bonusCalcData = {
                        mBaseCost: 0,
                        mSupDscnt1: 0,
                        mSupDscnt2: 0,
                        mSupDscnt3: 0,
                        mCostIncludeDscnt: 0,
                        mBonusQty: 0,
                        mQtyForBonusQty: 0,
                        mCostIncludeBonus: 0
                    }
                }
            }
        } else if (fieldName === 'totalSumNoTax') {
            // let _discount = parseFloat((1.00 - (val / (quantity * unitPriceNoTax))) * 100).toFixed(2)
            if (isTotalEdited === true) {
                //     //record.discountPercentage = parseFloat((1.00 - (val / (quantity * unitPriceNoTax))) * 100).toFixed(2);
                //     record.discountPercentage = isNaN(_discount) ? 0 : _discount;
                let totalWithTax = parseFloat(val * (1.00 + taxPercentageValue / 100))

                if (calcDscnts) {
                    const mBaseCost = record.unitPriceNoTax;
                    const mCostIncludeDscnt = parseFloat(val / quantity);
                    const mSupDscnt1 = parseFloat((1 - (mCostIncludeDscnt / mBaseCost)) * 100);
                    const { mBonusQty, mQtyForBonusQty } = record.bonusCalcData;
                    const mCostIncludeBonus = mCostIncludeDscnt * mQtyForBonusQty / (mQtyForBonusQty + mBonusQty)

                    record.bonusCalcData = {
                        mBaseCost,
                        mSupDscnt1,
                        mSupDscnt2: 0,
                        mSupDscnt3: 0,
                        mCostIncludeDscnt,
                        mBonusQty,
                        mQtyForBonusQty,
                        mCostIncludeBonus
                    }
                }

                record.totalSumNoTax = val;
                record.totalSumWithTax = totalWithTax.toFixed(4);
                record.discountPercentage = 0;
                record.unitPriceNoTax = parseFloat(val / quantity).toFixed(4);
                record.unitPriceWithTax = parseFloat(totalWithTax / quantity).toFixed(4);

            }
        } else if (fieldName === 'unitQuantity') {
            let quantity = parseFloat(val) * parseFloat(quantityInUnit);
            let totalSumNoTax = parseFloat((quantity * unitPriceNoTax) * (1.00 - discountPercentageValue / 100))
            record.unitQuantity = val
            record.quantity = quantity;
            //record.discountPercentage = discountPercentage;
            record.totalSumWithTax = parseFloat(totalSumNoTax * (1.00 + taxPercentageValue / 100)).toFixed(4);
            record.totalSumNoTax = totalSumNoTax.toFixed(4);
            // record.totalSumWithTax = parseFloat((val * unitPriceWithTax) * (1.00 - discountPercentage / 100)).toFixed(2);
            // record.totalSumNoTax = parseFloat((val * unitPriceNoTax) * (1.00 - discountPercentage / 100)).toFixed(2);
        } else if (fieldName === 'unitID') {
            let quantity = parseFloat(unitQuantity) * parseFloat(_quantityInUnit);
            let totalSumNoTax = parseFloat((quantity * unitPriceNoTax) * (1.00 - discountPercentageValue / 100))
            record.unitID = val;
            record.quantityInUnit = _quantityInUnit;
            record.unitQuantity = unitQuantity;
            record.unitDescription = this.getSizeDescription(val);
            record.quantity = parseFloat(unitQuantity) * parseFloat(_quantityInUnit);
            record.totalSumWithTax = parseFloat(totalSumNoTax * (1.00 + taxPercentageValue / 100)).toFixed(4);
            record.totalSumNoTax = totalSumNoTax.toFixed(4);
        } else if (fieldName === "costCurrencyValue") {
            let unitPriceNoTax = parseFloat(val * costCurrencyRate);
            let totalSumNoTax = parseFloat(quantity * unitPriceNoTax);
            record.costCurrencyValue = val;
            record.unitPriceNoTax = unitPriceNoTax.toFixed(4); //record.unitPriceWithTax = unitPriceWithTax;
            record.unitPriceWithTax = parseFloat(unitPriceNoTax * (1.00 + taxPercentageValue / 100)).toFixed(4);
            record.totalSumNoTax = totalSumNoTax.toFixed(4);
            record.totalSumWithTax = parseFloat(totalSumNoTax * (1.00 + taxPercentageValue / 100)).toFixed(4);
        } else {
            console.log("calculation type not recognized");
        }
        console.log("calculateRecord:  ", record);
        return record;
    }

    getSizeDescription = (_sizeID) => {
        // get description of sizeid of the ruler in store
        let obj = Jax.get(this.props.data, "UOM_Ruler.data", [])
            .find(i => (i.sizeID).toString() === _sizeID.toString());
        return (obj) ? obj['sizeDesc'] : '';
    }

    onLoadSelectorItems = (itemKeys) => {
        let _priceListId = this.props.data.document2.priceListId;
        let dataSend = "_itemId\f_priceListId\r" + itemKeys.join("\f" + _priceListId + "\r") + "\f" + _priceListId;
        this.props.sendAPI("get_more_data_for_item_list", dataSend, (ob) => {
            let selectorItemsDataSource2 = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f");
                return { key: y[0], iCostPrice: y[1], iSalePrice: y[2], iTagDefault: y[3] }
            }) : []
            this.setState({ selectorItemsDataSource2 })
        })
    }

    saveItemProfit = () => {
        let iProfit = this.state.modalItemProfit;
        let dataSource = this.props.dataSource.map(x => x.key === iProfit.key ? iProfit : x);
        this.props.dataActions.setJson("document2", { dataSource })
        this.setState({ modalItemProfit: null });
    }

    orderChange = (keyTarget) => {
        const { dataSource } = this.props.data.document2;
        let a = parseInt(this.drag_id);
        let b = parseInt(keyTarget);
        let nDataSource = dataSource.map(x => {
            let c = parseInt(x.key);
            if (c === a) return { ...x, key: keyTarget, keyVal: keyTarget }
            if (c < a && c < b) return x
            if (c > a && c > b) return x
            if (c > a && c <= b) return { ...x, key: (c - 1).toString(), keyVal: (c - 1).toString() }
            if (c < a && c >= b) return { ...x, key: (c + 1).toString(), keyVal: (c + 1).toString() }
        }).sort((aa, bb) => parseInt(bb.key) - parseInt(aa.key))
       
        this.props.dataActions.setJson("document2", { dataSource: nDataSource })
    }

    render() {
        console.log("render TableOptimized", this.props.ui)

        const { paging } = this.state;
        const { page, numOfPages, rowsPerPage } = paging;
        const { columns, editedRecord, documentType, isCostNoTax, isCostWithTax, linesOrderAsc } = this.props;
        const { onDataSourceChange, onEditedRecordChange, onAdd, onDelete, onEdit, onCancel, onUpdate, onUpdateData } = this.props;
        const { onItemSearchChangeSearch, onItemSearchChange, onItemSearchBlur, onItemSearchKeyDown, onLabelPrinterChange } = this.props;
        const { uiActions, ActionQueue } = this.props;
        const { config } = this.props;
        //config={this.props.config}

        const isSumFlag = this.props.isSumFlag;
        //const isCostWithTax = this.props.isCostWithTax;

        //{sizeID: "46", sizeDesc: "יחידה", sizeUOMQty: "1", itemRuler1ID: "16"}

        const trStyle = (ind) => {
            let colWidth = isCostNoTax && isCostWithTax ?
                documentType == 4 || documentType == 18 ? [3, 16, 20, 8, 4, 7, 7, 7, 7, 7, 7, 7] : [3, 18, 23, 9, 4, 7, 7, 7, 7, 8, 0, 7] :
                documentType == 4 || documentType == 18 ? [3, 18, 23, 9, 4, 7, 7, 7, 7, 8, 7] : [4, 20, 23, 10, 5, 7, 7, 7, 7, 10, 0]
            return { width: colWidth[ind] + "%" }
        };

        const selectorItemsColumns2 = [
            { title: this.getText(17170), key: "iCostPrice", dataIndex: "iCostPrice" },
            { title: this.getText(17171), key: "iSalePrice", dataIndex: "iSalePrice" },
            { title: this.getText(17172), key: "iTagDefault", dataIndex: "iTagDefault" },
        ]

        const createRowIfShown = () => {
            if (!editedRecord || !editedRecord.key || editedRecord.key === 0) {
                let rowProps = {
                    user: this.props.user, ActionQueue: this.props.ActionQueue, uiActions: this.props.uiActions, ui: this.props.ui, dataActions: this.props.dataActions, data: this.props.data,
                    columns: columns, editedRecord: editedRecord,
                    onAdd: record => {
                        onAdd(record)
                        if (linesOrderAsc) {
                            setTimeout(() => {
                                this.setState({ paging: { ...paging, page: this.calcNumOfPages() } })
                            }, 1000);
                        }
                    },
                    onUpdate: onUpdate, onUpdateData: onUpdateData,
                    linesOrderAsc: linesOrderAsc, setLastPage: newPaging => { this.setState({ paging: { ...this.state.paging, ...newPaging } }) },
                    onDataSourceChange: onDataSourceChange, onEditedRecordChange: onEditedRecordChange,
                    onItemSearchChangeSearch: onItemSearchChangeSearch, onItemSearchChange: onItemSearchChange, onItemSearchBlur: onItemSearchChange, onItemSearchKeyDown: onItemSearchChange,
                    tryShowUOMSizesModal: this.tryShowUOMSizesModal,
                    getUOMSizesRuler: this.getUOMSizesRuler,
                    api: this.props.api, callApi: this.props.callApi,
                    posId: this.props.posId,
                    isSumFlag: this.props.isSumFlag,
                    isCostNoTax: this.props.isCostNoTax,
                    isCostWithTax: this.props.isCostWithTax,
                    config: config,
                    isCtrlB: this.state.isCtrlB, buffer: this.state.buffer, isBufferFull: this.state.isBufferFull, onCtrlBChange: this.onCtrlBChange, onBufferChange: this.onBufferChange,
                    key: 0/*editedRecord.key*/,
                    keyVal: editedRecord.key,
                    itemCode: editedRecord.itemCode,
                    itemBarcode: editedRecord.itemBarcode,
                    serialNumber: editedRecord.serialNumber,
                    isValid: editedRecord.isValid,
                    description: editedRecord.description,
                    quantity: editedRecord.quantity,
                    unitPriceWithTax: editedRecord.unitPriceWithTax,
                    discountPercentage: editedRecord.discountPercentage,
                    totalSumWithTax: editedRecord.totalSumWithTax,
                    taxId: editedRecord.taxId,
                    taxPercentageValue: editedRecord.taxPercentageValue,
                    unitPriceNoTax: editedRecord.unitPriceNoTax,
                    totalSumNoTax: editedRecord.totalSumNoTax,

                    remarks: editedRecord.remarks,

                    unitID: editedRecord.unitID,
                    quantityInUnit: editedRecord.quantityInUnit,
                    unitQuantity: editedRecord.unitQuantity,
                    unitDescription: editedRecord.unitDescription,

                    costCurrencyId: editedRecord.costCurrencyId,
                    costCurrencySymbol: editedRecord.costCurrencySymbol,
                    costCurrencyRate: editedRecord.costCurrencyRate,
                    costCurrencyValue: editedRecord.costCurrencyValue,

                    isWeightable: editedRecord.isWeightable,
                    isSerialize: editedRecord.isSerialize,

                    calculateRecord: this.calculateRecord,
                    getSizeDescription: this.getSizeDescription,
                    onBlurItemInput: this.props.onBlurItemInput,
                    disabledAddItem: this.props.disabledAddItem,
                    setDisabledAddItem: this.props.setDisabledAddItem,

                    documentType: this.props.documentType,
                    scanDocOpen: this.props.scanDocOpen,


                    onLoadSelectorItems: this.onLoadSelectorItems,
                    selectorItemsDataSource2: this.state.selectorItemsDataSource2,
                    selectorItemsColumns2: selectorItemsColumns2,

                    costCalcOption: this.props.costCalcOption,
                    bonusCalcData: editedRecord.bonusCalcData,

                }

                return (<TableRowCreate {...rowProps} />)
            }
        }

        const iProfit = this.state.modalItemProfit;
        const onChangeItemProfit = (obj) => { this.setState({ modalItemProfit: { ...iProfit, ...obj } }) }
        const sumFormat = (sum) => Math.round(sum * 100) / 100;
        const renderProfitField = (t, record) => {
            let isTotalCol = t === 'totalQty';
            let text = isTotalCol ? sumFormat(record.unit * iProfit.quantity) : t
            let taxCalc = 1 + (iProfit.taxPercentageValue / 100);
            switch (record.field) {
                case 'cost':
                    return (<InputNumber precision={2} value={text} onChange={val => {
                        let v = isTotalCol ? sumFormat(val / iProfit.quantity) : val;
                        onChangeItemProfit({
                            cost: v,
                            profitUnitSum: iProfit.unitPriceNoTax - v - (iProfit.discountUnitSum / taxCalc)
                        })
                    }} />);
                case 'noTax':
                    return (<InputNumber precision={2} value={text} onChange={val => {
                        let v = isTotalCol ? sumFormat(val / iProfit.quantity) : val;
                        let unitPriceWithTax = sumFormat(v * taxCalc);
                        let totalSumWithTax = sumFormat((unitPriceWithTax - iProfit.discountUnitSum) * iProfit.quantity);
                        onChangeItemProfit({
                            unitPriceNoTax: v,
                            unitPriceWithTax,
                            totalSumNoTax: sumFormat(totalSumWithTax / taxCalc),
                            totalSumWithTax,
                            profitUnitSum: v - iProfit.cost - (iProfit.discountUnitSum / taxCalc)
                        })
                    }} />);
                case 'withTax':
                    return (<InputNumber precision={2} value={text} onChange={val => {
                        let v = isTotalCol ? sumFormat(val / iProfit.quantity) : val;
                        let unitPriceNoTax = sumFormat(v / taxCalc);
                        let totalSumWithTax = sumFormat((v - iProfit.discountUnitSum) * iProfit.quantity);
                        onChangeItemProfit({
                            unitPriceNoTax,
                            unitPriceWithTax: v,
                            totalSumNoTax: sumFormat(totalSumWithTax / taxCalc),
                            totalSumWithTax,
                            profitUnitSum: unitPriceNoTax - iProfit.cost - (iProfit.discountUnitSum / taxCalc)
                        })
                    }} />);
                case 'discount':
                    return (<InputNumber precision={2} value={text} onChange={val => {
                        let v = isTotalCol ? sumFormat(val / iProfit.quantity) : val;
                        let totalSumWithTax = sumFormat((iProfit.unitPriceWithTax - v) * iProfit.quantity);
                        onChangeItemProfit({
                            discountUnitSum: v,
                            discountPercentage: sumFormat(v / iProfit.unitPriceWithTax * 100),
                            totalSumNoTax: sumFormat(totalSumWithTax / taxCalc),
                            totalSumWithTax,
                            profitUnitSum: iProfit.unitPriceNoTax - iProfit.cost - (v / taxCalc)
                        })
                    }} />);
                case 'total':
                    return (<InputNumber precision={2} value={text} onChange={val => {
                        let v = isTotalCol ? sumFormat(val / iProfit.quantity) : val;
                        let discountUnitSum = iProfit.unitPriceWithTax - v;
                        let totalSumWithTax = sumFormat(v * iProfit.quantity);
                        onChangeItemProfit({
                            discountUnitSum,
                            discountPercentage: sumFormat(discountUnitSum / iProfit.unitPriceWithTax * 100),
                            totalSumNoTax: sumFormat(totalSumWithTax / taxCalc),
                            totalSumWithTax,
                            profitUnitSum: iProfit.unitPriceNoTax - iProfit.cost - (discountUnitSum / taxCalc)
                        })
                    }} />);

                case 'profit':
                    return (<InputNumber precision={2} value={text} onChange={val => {
                        let v = isTotalCol ? sumFormat(val / iProfit.quantity) : val;
                        let unitPriceNoTax = sumFormat(iProfit.cost + (iProfit.discountUnitSum / taxCalc) + v);
                        let unitPriceWithTax = sumFormat(unitPriceNoTax * taxCalc);
                        let totalSumWithTax = sumFormat((unitPriceWithTax - iProfit.discountUnitSum) * iProfit.quantity);
                        onChangeItemProfit({
                            unitPriceNoTax,
                            unitPriceWithTax,
                            totalSumNoTax: sumFormat(totalSumWithTax / taxCalc),
                            totalSumWithTax,
                            profitUnitSum: v
                        })
                    }} />);
                default: return text;
            }
        }


        return (
            <div className={styles.outerTableOptimized} >
                <table className={styles.borderedTableOptimized}>
                    <thead>
                        {
                            (window.innerWidth > 600)
                                ?
                                <tr style={{ backgroundColor: '#d5dfed' }}>
                                    <th style={trStyle(0)} colSpan={1} >{this.getText(12513)}</th>
                                    <th style={trStyle(1)} colSpan={2} >{this.getText(12514)}</th>
                                    <th style={trStyle(2)} colSpan={3} >{this.getText(12515)}</th>
                                    <th style={trStyle(3)} colSpan={2} >{this.getText(16810)}</th>{/* new */}
                                    <th style={trStyle(4)} colSpan={1} >{this.getText(16811)}</th>{/* new */}
                                    <th style={trStyle(5)} colSpan={1} >{this.getText(12516)}</th>

                                    {this.props.documentType == 4 || this.props.documentType == 18 ?
                                        <th style={trStyle(10)} colSpan={1} >{this.getText(18192)}</th>
                                        : ""}

                                    <th style={trStyle(6)} colSpan={1} >{this.getText(isCostNoTax ? 12517 : 18627)}</th>
                                    {isCostNoTax && isCostWithTax ? <th style={trStyle(11)} colSpan={1} >{this.getText(18627)}</th> : ""}
                                    <th style={trStyle(7)} colSpan={1} >{this.getText(12518)}</th>
                                    <th style={trStyle(8)} colSpan={1} >{this.getText(12519)}</th>
                                    <th style={trStyle(9)} colSpan={1} ></th>
                                </tr>

                                :
                                <tr style={{ backgroundColor: '#d5dfed' }}>
                                    <th style={{ width: '15%' }} >תיאור</th>
                                    <th style={{ width: '75%' }} >רשומה</th>
                                    <th style={{ width: '10%' }} >עריכה</th>
                                </tr>
                        }
                    </thead>
                    <tbody>
                        {createRowIfShown()}
                        {(this.props.loading === true)
                            ? <Icon spin={true} type="loading" />
                            : (this.props.dataSource)
                                .filter((i, index) => {
                                    return (index + 1 >= (page - 1) * rowsPerPage + 1 && index + 1 <= page * rowsPerPage) ? true : false;
                                })
                                .map(i => {
                                    if (i.key === editedRecord.key) {
                                        // edited row
                                        return (
                                            <TableRowEditable
                                                //<TableRowCreate
                                                user={this.props.user} ActionQueue={this.props.ActionQueue} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data}
                                                columns={columns} editedRecord={editedRecord}
                                                onDataSourceChange={onDataSourceChange} onEditedRecordChange={onEditedRecordChange}

                                                onItemSearchChangeSearch={onItemSearchChangeSearch}
                                                onItemSearchChange={onItemSearchChange}
                                                onItemSearchBlur={onItemSearchChange}
                                                onItemSearchKeyDown={onItemSearchChange}
                                                onAdd={record => {
                                                    onAdd(record);
                                                    if (linesOrderAsc) {
                                                        setTimeout(() => {
                                                            this.setState({ paging: { ...paging, page: this.calcNumOfPages() } })
                                                        }, 1000);
                                                    }
                                                }}
                                                onEdit={onEdit} onCancel={onCancel} onUpdate={onUpdate}
                                                linesOrderAsc={linesOrderAsc}
                                                tryShowUOMSizesModal={this.tryShowUOMSizesModal}
                                                getUOMSizesRuler={this.getUOMSizesRuler}
                                                api={this.props.api} callApi={this.props.callApi}
                                                posId={this.props.posId}

                                                isCostNoTax={this.props.isCostNoTax}
                                                isCostWithTax={this.props.isCostWithTax}
                                                config={config}

                                                key={editedRecord.key}
                                                keyVal={editedRecord.key}
                                                itemCode={editedRecord.itemCode}
                                                itemBarcode={editedRecord.itemBarcode}
                                                serialNumber={editedRecord.serialNumber}

                                                isValid={editedRecord.isValid}

                                                description={editedRecord.description}
                                                quantity={editedRecord.quantity}
                                                unitPriceWithTax={editedRecord.unitPriceWithTax}
                                                discountPercentage={editedRecord.discountPercentage}
                                                totalSumWithTax={editedRecord.totalSumWithTax}
                                                taxId={editedRecord.taxId}
                                                taxPercentageValue={editedRecord.taxPercentageValue}

                                                unitPriceNoTax={editedRecord.unitPriceNoTax}
                                                totalSumNoTax={editedRecord.totalSumNoTax}

                                                remarks={editedRecord.remarks}

                                                unitID={editedRecord.unitID}
                                                quantityInUnit={editedRecord.quantityInUnit}
                                                unitQuantity={editedRecord.unitQuantity}
                                                unitDescription={editedRecord.unitDescription}

                                                costCurrencyId={editedRecord.costCurrencyId}
                                                costCurrencySymbol={editedRecord.costCurrencySymbol}
                                                costCurrencyRate={editedRecord.costCurrencyRate}
                                                costCurrencyValue={editedRecord.costCurrencyValue}

                                                isWeightable={editedRecord.isWeightable}
                                                isSerialize={editedRecord.isSerialize}

                                                calculateRecord={this.calculateRecord}
                                                getSizeDescription={this.getSizeDescription}
                                                onBlurItemInput={this.props.onBlurItemInput}
                                                disabledAddItem={this.props.disabledAddItem}
                                                setDisabledAddItem={this.props.setDisabledAddItem}

                                                documentType={this.props.documentType}

                                                scanDocOpen={this.props.scanDocOpen}
                                                onLoadSelectorItems={this.onLoadSelectorItems}
                                                selectorItemsDataSource2={this.state.selectorItemsDataSource2}
                                                selectorItemsColumns2={selectorItemsColumns2}
                                                costCalcOption={this.props.costCalcOption}
                                                bonusCalcData={editedRecord.bonusCalcData}

                                            />)
                                    }
                                    else {
                                        // data rows
                                        return (<TableRow
                                            data={this.props.data}
                                            dataActions={this.props.dataActions}

                                            columns={columns}
                                            editedRecord={editedRecord}
                                            onLabelPrinterChange={onLabelPrinterChange}
                                            uiActions={uiActions}
                                            onDelete={onDelete}
                                            onEdit={onEdit}
                                            key={i.key}
                                            keyVal={i.key}
                                            itemCode={i.itemCode}
                                            serialNumber={i.serialNumber}
                                            isValid={i.isValid}
                                            description={i.description}
                                            quantity={i.quantity}
                                            /* (this.state) */
                                            unitPriceWithTax={i.unitPriceWithTax}
                                            discountPercentage={i.discountPercentage}
                                            totalSumWithTax={i.totalSumWithTax}

                                            unitPriceNoTax={i.unitPriceNoTax}
                                            totalSumNoTax={i.totalSumNoTax}

                                            remarks={i.remarks}

                                            unitID={i.unitID}
                                            quantityInUnit={i.quantityInUnit}
                                            unitQuantity={i.unitQuantity}
                                            unitDescription={i.unitDescription/*this.getSizeDescription(i.unitID)*/}

                                            costCurrencyId={i.costCurrencyId}
                                            costCurrencySymbol={i.costCurrencySymbol}
                                            costCurrencyRate={i.costCurrencyRate}
                                            costCurrencyValue={i.costCurrencyValue}

                                            isCostNoTax={this.props.isCostNoTax}
                                            isCostWithTax={this.props.isCostWithTax}

                                            isWeightable={i.isWeightable}
                                            isSerialize={i.isSerialize}

                                            config={config}
                                            onBlurItemInput={this.props.onBlurItemInput}
                                            disabledAddItem={this.props.disabledAddItem}
                                            setDisabledAddItem={this.props.setDisabledAddItem}

                                            documentType={this.props.documentType}

                                            openModalCreateEditItem={() => {
                                                if (i.isValid) {
                                                    this.props.openModalCreateEditItem(i.itemCode)
                                                } else {
                                                    this.props.openModalCreateEditItem('');
                                                    setTimeout(() => {

                                                        let newRecord = {
                                                            ...this.props.data.ITEM_CREATE_EDIT.editedRecord,
                                                            mCode: i.itemCode,
                                                        }

                                                        let d2 = this.props.data.document2;

                                                        let createNewFromDoc = {
                                                            documentType: this.props.documentType,
                                                            itemCode: i.itemCode,
                                                            description: i.description,
                                                            priceListId: d2.priceListId,
                                                            unitPriceNoTax: i.unitPriceNoTax,
                                                            unitPriceWithTax: i.unitPriceWithTax,
                                                            supplierCode: d2.supplierCode,

                                                        }
                                                        this.props.dataActions.setJson('ITEM_CREATE_EDIT', {
                                                            editedRecord: newRecord,
                                                            createNewFromDoc,
                                                        })
                                                    }, 1200);
                                                }
                                            }}

                                            openModalProfitItem={() => {
                                                let discountUnitSum = i.unitPriceWithTax * i.discountPercentage * 0.01;
                                                let profitUnitSum = i.unitPriceNoTax - i.cost - discountUnitSum;
                                                let profitCalc = "up";
                                                let docType = this.props.documentType;
                                                let custId = this.props.data.document2.customerCode;
                                                this.setState({ modalItemProfit: { ...i, discountUnitSum, profitUnitSum, profitCalc, docType, custId } })
                                            }}

                                            onDrag={() => { this.drag_id = i.key }}
                                            onDrop={() => { this.orderChange(i.key) }}
                                        />)
                                    }
                                })
                        }
                    </tbody>
                </table>
                <Paging
                    totalRows={this.rowCount()}
                    paging={{ ...this.state.paging, numOfPages: this.calcNumOfPages() }}
                    onInputChange={(pageNum) => {
                        let _paging = { ...this.state.paging, page: pageNum }
                        this.setState({ paging: _paging })
                    }}
                    onChange={({ page, numOfPages, rowsPerPage }) => {
                        this.setState({
                            paging: {
                                page: page,
                                //numOfPages: numOfPages,
                                rowsPerPage: rowsPerPage,
                            }
                        })
                    }}
                />
                <GenericModal
                    visible={iProfit !== null}
                    onCancel={() => { this.setState({ modalItemProfit: null }) }}
                    title={this.getText(17174) + " " + (iProfit ? iProfit.description : "")}
                    width={900}
                    footer={[
                        <Button onClick={() => { this.setState({ modalItemProfit: null }) }}>{this.getText(17190)}</Button>,
                        <Button type="primary" onClick={this.saveItemProfit}>{this.getText(17189)}</Button>
                    ]}>
                    {iProfit ? (<Row>
                        <Col span={16}>
                            <h3>
                                {this.getText(17183) + ": "}
                                {sumFormat(iProfit.profitUnitSum / (iProfit.profitCalc == 'up' ? iProfit.totalSumWithTax / iProfit.quantity : iProfit.cost) * 100)}
                                %
                            </h3>
                            <h3>
                                {this.getText(17184)}:
                                <Radio.Group value={iProfit.profitCalc} onChange={e => { onChangeItemProfit({ profitCalc: e.target.value }) }}>
                                    <Radio value="up">{this.getText(17185)}</Radio>
                                    <Radio value="down">{this.getText(17186)}</Radio>
                                </Radio.Group>
                            </h3>
                            <ResponsiveTable
                                tableOnly
                                pagination={false}
                                dataSource={[
                                    { text: this.getText(17178), unit: sumFormat(iProfit.cost), field: 'cost' },
                                    { text: this.getText(17179), unit: sumFormat(iProfit.unitPriceNoTax), field: 'noTax' },
                                    { text: this.getText(17188), unit: sumFormat(iProfit.unitPriceWithTax), field: 'withTax' },
                                    { text: this.getText(17180), unit: sumFormat(iProfit.discountUnitSum), field: 'discount' },
                                    { text: this.getText(17181), unit: sumFormat(iProfit.totalSumWithTax / iProfit.quantity), field: 'total' },
                                    { text: this.getText(17182), unit: sumFormat(iProfit.profitUnitSum), field: 'profit' },
                                ]}
                                columns={[
                                    { title: "", key: "text", dataIndex: "text", width: "40%", render: t => t },
                                    { title: this.getText(17176), key: "unit", dataIndex: "unit", width: "30%", render: renderProfitField },
                                    { title: this.getText(17177) + ' (' + parseFloat(iProfit.quantity) + ')', width: "30%", render: (t, r) => renderProfitField('totalQty', r) },
                                ]}
                            />
                        </Col>
                        <Col span={8} style={{ paddingRight: 10, paddingTop: 33 }}>
                            <h3>{this.getText(17381)}</h3>
                            <GenericTable
                                {...this.props}
                                tableOnly
                                id={this.id_lastPrices}
                                getApi={"get_last_prices_for_cust"}
                                filters={{
                                    _cust: iProfit.custId,
                                    _itemCode: iProfit.itemCode,
                                    _docType: iProfit.docType,
                                    _rows_num: 6,
                                    recordsPerPage: 6,
                                    _isAsc: 0,
                                    sortType: 0,
                                }}
                                columns={[
                                    { title: this.getText(17379), key: "pDatetime", dataIndex: "pDatetime", width: "50%", render: t => t },
                                    { title: this.getText(17380), key: "pUnitWithTax", dataIndex: "pUnitWithTax", width: "50%", render: t => Math.round(t * 100) / 100 },
                                ]}
                                onRowClick={record => {
                                    let v = sumFormat(record.pUnitWithTax);
                                    let taxCalc = 1 + (iProfit.taxPercentageValue / 100);
                                    let unitPriceNoTax = sumFormat(v / taxCalc);
                                    let totalSumWithTax = sumFormat((v - iProfit.discountUnitSum) * iProfit.quantity);
                                    onChangeItemProfit({
                                        unitPriceNoTax,
                                        unitPriceWithTax: v,
                                        totalSumNoTax: sumFormat(totalSumWithTax / taxCalc),
                                        totalSumWithTax,
                                        profitUnitSum: unitPriceNoTax - iProfit.cost - (iProfit.discountUnitSum / taxCalc)
                                    })
                                }}
                                rowClassName={"clickable"}
                            />
                        </Col>
                    </Row>)
                        : ""}
                </GenericModal>
            </div>

        )
    }

}
export default TableOptimized;

// ######################################################
class TableRowCreate extends PureComponent/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.buffer = "";
        this.isCtrlB = 0; //  0-regular | 1-ctrlB start | 2-ctrlB end
        //this.isTotalEdited = false;
        this.isBigQuantityAllowed = 0;
        this.state = {
            openModalOnBlurQty: false,
        }
    }

    static defaultProps = {
        key: 0,
        keyVal: 0,
        isValid: 0,
        itemCode: null,
        itemBarcode: null,
        description: null,
        quantity: 0.00,
        unitPriceWithTax: null,
        discountPercentage: null,
        totalSumWithTax: null,

        unitPriceNoTax: null,
        totalSumNoTax: null,
        isWeightable: 0,
    }

    static contextType = LangContext;


    setAttributeRangeBySign = (_quantity, _unitPrice) => {
        return (parseFloat(_unitPrice) === 0)
            ? { min: 0, max: 0 }
            : (_quantity > 0) ? { min: 0 } : { max: 0 }
    }

    getUOMqty = (_IsMatrixReverseFather, _UOM_Qty): Number => {
        return (_IsMatrixReverseFather && (_IsMatrixReverseFather === 1 || _IsMatrixReverseFather === '1')) ? _UOM_Qty * 1.00 : 1.00;

    }

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    onChange = (record, field, value) => {
        this.props.onEditedRecordChange({ ...record, [field]: value });
    }
    onRecordChange = (record, changesObj) => {
        this.props.onEditedRecordChange({ ...record, ...changesObj });
    }

    checkItemAndDo2 = (itemCodeParam/* RecordObj */) => {
        console.log("checkItemAndDo2", itemCodeParam)
        const { keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, remarks, isSerialize, bonusCalcData } = this.props;
        const { onDataSourceChange, onEditedRecordChange, columns, editedRecord, onItemSearchChangeSearch, getSizeDescription } = this.props
        let record = { key: keyVal, keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, remarks, bonusCalcData };
        const { onAdd, onUpdate, onUpdateData, linesOrderAsc } = this.props;
        const isSumFlag = this.props.isSumFlag;
        const { isCostWithTax, isCostNoTax } = this.props;

        let _supplier = this.props.data.document2.filterItemsPerSupplier == '1' ? this.props.data.document2.supplierCode : '0';
        let val = itemCodeParam;
        //let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + val + "\f1\f1";
        let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\f_supplier\r" + val + "\f1\f1\f" + _supplier;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, 'check_all_item_code_per_supplier', OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob: Object) => {
                let responseArr = null;
                if (ob.data === "") {
                    responseArr = []
                } else {
                    responseArr = (ob.data).split('\r');
                }

                if (responseArr.length > 1) {
                    // not an exact match
                    let r = {
                        ...record, itemCode: itemCodeParam, //_ItemCode, 
                        description: "-ברקוד אינו פריט יחודי-", //_ItemName,
                        quantity: this.props.documentType == '21' || this.props.documentType == '22' || this.props.documentType == '9' ? -1.00 : 1.00,
                        isValid: 0,
                        unitPriceWithTax: 0.00,
                        discountPercentage: 0.00,
                        totalSumWithTax: 0.00,
                    }
                    //onEditedRecordChange(r);
                    onAdd(r);

                    return;

                }

                if (responseArr.length === 0 /*|| !_ItemCode*/) {
                    // item not found
                    console.log("item not found");

                    let r = {
                        ...record, itemCode: itemCodeParam, //_ItemCode, 
                        description: "-פריט לא קיים-", //_ItemName,
                        quantity: this.props.documentType == '21' || this.props.documentType == '22' || this.props.documentType == '9' ? -1.00 : 1.00,
                        isValid: 0,
                        unitPriceWithTax: 0.00,
                        discountPercentage: 0.00,
                        totalSumWithTax: 0.00,
                    }
                    //onEditedRecordChange(r);
                    onAdd(r);

                    //this.props.uiActions.showModal(TableIDs.SelectorItemsModal, {});
                    return;
                }

                let resArr = responseArr[0].split('\f');

                //console.log("rrrrrrrrrrr99: ", resArr);

                let _ItemId = resArr[0];
                let _ItemCode = resArr[1];
                let _ItemName = resArr[2];
                let _IsMatrix = resArr[3];
                let _IsMatrixFather = resArr[4];
                let _ItemBarCode = resArr[5];
                let _ItemSize1ID = resArr[6];
                let _ItemColorID = resArr[7];

                let _Size1Desc = resArr[8];
                let _ColorDesc = resArr[9];

                let _IsMatrixReverseFather = resArr[10];
                let _IsMatrixReverse = resArr[11];
                let _UOM_Qty = resArr[12];
                let _IsValid = (this.props.data.document2.dataSource.filter(i => i.itemCode === _ItemCode && i.isValid == 1).length) ? 5 : parseInt(resArr[13]);


                //console.log("gggggg", _ItemCode)
                //console.log("rrrrrrrrrrrr: ", _ItemSize1ID, _Size1Desc);

                if (_IsMatrixFather === '1') {
                    // item found and is matrix father
                    //let modalParamObj = { ...this.props.ui.data, mCode: _ItemCode };
                    //this.props.uiActions.showModal(TableIDs.modalMatrixQuantities, modalParamObj);
                    let r = {
                        ...record, itemCode: itemCodeParam, //_ItemCode, 
                        description: "-פריט אב-", //_ItemName,
                        quantity: this.props.documentType == '21' || this.props.documentType == '22' || this.props.documentType == '9' ? -1.00 : 1.00,
                        isValid: 0,
                        unitPriceWithTax: 0.00,
                        discountPercentage: 0.00,
                        totalSumWithTax: 0.00,
                    }
                    //onEditedRecordChange(r);
                    onAdd(r);

                    return;
                }
                // item found and is regular
                // get item select price/cost
                let dataSend = "_itemCode\f_customerId\f_supplierId\f_priceListId\f_posId\f_docTime\r" +
                    [_ItemCode,
                        this.props.data.document2.customerCode,
                        this.props.data.document2.supplierCode, //null,
                        this.props.data.document2.priceListId,
                        this.props.posId,
                        this.props.data.document2.docDateTime
                    ].join("\f");
                let user = new User(this.props.user.token, this.props.user.companyCode);
                let job = new Job(user, "get_item_line_meta", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
                job.setInput(dataSend);
                job.send("/cgi-bin/CashOnTab", (ob: Object) => {
                    let resArr = (ob.data).split('\f');
                    let _unitCost = resArr[0];
                    let _price = resArr[1];
                    let _unitCostNoTax = resArr[2];
                    let _priceNoTax = resArr[3];
                    let _taxId = resArr[4];
                    let _taxPercentageValue = resArr[5];
                    let _supplierId = resArr[6];
                    let _isSerialize = resArr[7];
                    let _isWeightable = resArr[8];

                    if (this.props.config.isUnitCost) {
                        if (this.props.costCalcOption == '1' && resArr[20]) {
                            _priceNoTax = resArr[20];
                            _price = _priceNoTax * ((parseFloat(_taxPercentageValue) + 100) / 100);
                        } else {
                            _price = _unitCost;
                            _priceNoTax = _unitCostNoTax;
                        }
                    }

                    // console.log('this.state.hidePrices && this.props.documentType', this.props.hidePrices, this.props.documentType)
                    if (this.props.hidePrices && this.props.documentType == '15') {
                        _unitCost = 0;
                        _price = 0;
                        _unitCostNoTax = 0;
                        _priceNoTax = 0;
                    }

                    let _remarks = '';
                    if (parseInt(_IsMatrix, 10) === 1) {
                        _remarks = encodeXml(_remarks, 'Color', _ColorDesc);
                        _remarks = encodeXml(_remarks, 'Size1', _Size1Desc);
                    }
                    if (resArr[24]) _remarks += resArr[24];

                    let _q = this.getUOMqty(_IsMatrixReverseFather, _UOM_Qty);
                    if (this.props.documentType == '21' || this.props.documentType == '22' || this.props.documentType == '9') _q = _q * -1
                    //let _q = (_IsMatrixReverseFather && (_IsMatrixReverseFather === 1 || _IsMatrixReverseFather === '1')) ? _UOM_Qty * 1.00 : 1.00;
                    //let _q = 1;

                    /*
    unitID: i.unitID, 
    quantityInUnit: i.quantityInUnit, 
    unitQuantity: i.unitQuantity,
                    */
                    let customerDiscount = this.props.data.document2.customerDiscount ? this.props.data.document2.customerDiscount : 0;

                    if (isSumFlag === true && (isSerialize ?? '0') == '0') {
                        const last_record = [...this.props.data.document2.dataSource]
                            .reverse()
                            .find(i => {
                                return ((i.itemCode === _ItemCode/*itemCodeParam*/)
                                    && (parseFloat(i.discountPercentage) === 0.00)
                                    && (parseFloat(i.unitPriceWithTax) === parseFloat(_price))
                                    && (parseInt((i.unitID) ? i.unitID : 0) === parseInt((_ItemSize1ID) ? _ItemSize1ID : 0))
                                )
                            });

                        if (last_record) {
                            onUpdateData({
                                ...last_record,
                                quantity: parseFloat(last_record.quantity) + 1.00 * _q,
                                unitPriceWithTax: parseFloat(_price).toFixed(4), //_unitCost,//1,
                                unitPriceNoTax: parseFloat(_priceNoTax).toFixed(4), //_unitCost,//1,
                                //discountPercentage: 0,
                                totalSumWithTax: (parseFloat(_price) * (parseFloat(last_record.quantity) + _q) * (1 - parseFloat(last_record.discountPercentage) / 100)).toFixed(4),
                                totalSumNoTax: (parseFloat(_priceNoTax) * (parseFloat(last_record.quantity) + _q) * (1 - parseFloat(last_record.discountPercentage) / 100)).toFixed(4),
                                taxId: _taxId,
                                taxPercentageValue: parseFloat(_taxPercentageValue),
                                remarks: _remarks,

                                //unitID: _ItemSize1ID, //_Size1Desc,
                                //quantityInUnit: _q,
                                unitQuantity: parseFloat(last_record.unitQuantity) + 1.00,
                                //unitDescription: `${getSizeDescription(_ItemSize1ID)}(${_q})`,//`$DESC(${_q})`,
                                isWeightable: _isWeightable,
                                isSerialize: _isSerialize,

                                cost: parseFloat(_unitCostNoTax).toFixed(4),
                                supplierId: _supplierId,

                                costCurrencyId: resArr[10],
                                costCurrencySymbol: resArr[11],
                                costCurrencyValue: resArr[12],
                                costCurrencyRate: resArr[13],

                                bonusCalcData: {
                                    mBaseCost: resArr[16],
                                    mSupDscnt1: resArr[17],
                                    mSupDscnt2: resArr[18],
                                    mSupDscnt3: resArr[19],
                                    mCostIncludeDscnt: resArr[20],
                                    mBonusQty: resArr[21],
                                    mQtyForBonusQty: resArr[22],
                                    mCostIncludeBonus: resArr[23]
                                }

                            })
                        } else {
                            onAdd({
                                ...record,
                                itemCode: _ItemCode,
                                itemBarcode: _ItemBarCode,
                                description: _ItemName,
                                isValid: _IsValid,
                                quantity: 1.00 * _q,
                                unitPriceWithTax: parseFloat(_price).toFixed(4),//_unitCost,//1,
                                unitPriceNoTax: parseFloat(_priceNoTax).toFixed(4),//_unitCost,//1,
                                discountPercentage: customerDiscount,
                                totalSumWithTax: (parseFloat(_price) * _q * (1 - customerDiscount / 100)).toFixed(4),
                                totalSumNoTax: (parseFloat(_priceNoTax) * _q * (1 - customerDiscount / 100)).toFixed(4),
                                taxId: _taxId,
                                taxPercentageValue: parseFloat(_taxPercentageValue),
                                remarks: _remarks,

                                unitID: _ItemSize1ID, //_Size1Desc,
                                quantityInUnit: _q,
                                unitQuantity: 1.00,
                                // unitDescription: `${(_Size1Desc) ? _Size1Desc : ''}(${_q})`,//`$DESC(${_q})`,
                                unitDescription: (_Size1Desc) ? _Size1Desc : '',
                                isWeightable: _isWeightable,
                                isSerialize: _isSerialize,

                                cost: parseFloat(_unitCostNoTax).toFixed(4),
                                supplierId: _supplierId,

                                costCurrencyId: resArr[10],
                                costCurrencySymbol: resArr[11],
                                costCurrencyValue: resArr[12],
                                costCurrencyRate: resArr[13],

                                bonusCalcData: {
                                    mBaseCost: resArr[16],
                                    mSupDscnt1: resArr[17],
                                    mSupDscnt2: resArr[18],
                                    mSupDscnt3: resArr[19],
                                    mCostIncludeDscnt: resArr[20],
                                    mBonusQty: resArr[21],
                                    mQtyForBonusQty: resArr[22],
                                    mCostIncludeBonus: resArr[23]
                                }

                            });
                        }
                        // get record by itemCode
                        // if exists update last record quantity 
                        // else onAdd  
                    } else {
                        //console.log("rrrrrrrrrrrr2: ", _Size1Desc);


                        onAdd({
                            ...record,
                            itemCode: _ItemCode,
                            itemBarcode: _ItemBarCode,
                            description: _ItemName,
                            isValid: _IsValid,
                            quantity: _q,
                            unitPriceWithTax: parseFloat(_price).toFixed(4),//_unitCost,//1,
                            unitPriceNoTax: parseFloat(_priceNoTax).toFixed(4),//_unitCost,//1,
                            discountPercentage: customerDiscount,
                            totalSumWithTax: (parseFloat(_price) * _q * (1 - customerDiscount / 100)).toFixed(4),
                            totalSumNoTax: (parseFloat(_priceNoTax) * _q * (1 - customerDiscount / 100)).toFixed(4),
                            taxId: _taxId,
                            taxPercentageValue: parseFloat(_taxPercentageValue),
                            remarks: _remarks,

                            unitID: _ItemSize1ID, //_Size1Desc,
                            quantityInUnit: _q,
                            unitQuantity: 1.00,
                            // unitDescription: `${(_Size1Desc) ? _Size1Desc : ''}(${_q})`, //`$DESC(${_q})`,
                            unitDescription: (_Size1Desc) ? _Size1Desc : '',
                            isWeightable: _isWeightable,
                            isSerialize: _isSerialize,

                            cost: parseFloat(_unitCostNoTax).toFixed(4),
                            supplierId: _supplierId,

                            costCurrencyId: resArr[10],
                            costCurrencySymbol: resArr[11],
                            costCurrencyValue: resArr[12],
                            costCurrencyRate: resArr[13],

                            bonusCalcData: {
                                mBaseCost: resArr[16],
                                mSupDscnt1: resArr[17],
                                mSupDscnt2: resArr[18],
                                mSupDscnt3: resArr[19],
                                mCostIncludeDscnt: resArr[20],
                                mBonusQty: resArr[21],
                                mQtyForBonusQty: resArr[22],
                                mCostIncludeBonus: resArr[23]
                            }
                        });
                    }
                }, (error: any) => {
                    message.error('שגיאה לא ידועה');
                });
            },
            (error: any) => {
                message.error('שגיאה לא ידועה');
            }
        );
        //}
    }

    checkIsModalsClosed = () => {
        return !(this.props.ui.modalStack.includes(TableIDs.modalMatrixQuantities)
            || this.props.ui.modalStack.includes(TableIDs.SelectorItemsModal))
    }

    clearInput = () => {
        // clear item selector input
        const {
            keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, taxPercentageValue,
            taxId, unitPriceNoTax, totalSumNoTax, remarks, unitDescription, isWeightable,
            costCurrencyId, costCurrencySymbol, costCurrencyRate, costCurrencyValue, bonusCalcData
        } = this.props;
        let record = { key: keyVal, keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, taxPercentageValue, taxId, unitPriceNoTax, totalSumNoTax, remarks, unitDescription, isWeightable, bonusCalcData };
        this.props.onEditedRecordChange({ ...record, itemCode: '' });
    }

    keyboardListener = (event: KeyboardEvent) => {
        console.log("keyboardListener item ", event.key, this.props.scanDocOpen)
        if (!this.props.ui.modalStack.includes(TableIDs.modalDocumentSelector) && !this.props.scanDocOpen) {
            const keyName = event.key;
            const keyCode = event.keyCode;
            const code = event.code;
            switch (keyCode) {
                case 66://"keyB":
                case 77://"keyM":
                    if (event.ctrlKey) {
                        if (this.checkIsModalsClosed()) {
                            this.clearInput();
                            document.getElementById("search_item_input_id"/*"first_input"*/).focus();
                            this.buffer = "";
                            this.isCtrlB = 1;
                        }
                    }
                    break;
                case 13://"Enter":
                    if (this.isCtrlB === 1) {
                        this.isCtrlB = 2;
                    }
                    break;
            }
        }

        // if (event.key === "ArrowDown") {
        //     if (!this.props.disabledAddItem) {
        //          const {
        //             keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, taxPercentageValue,
        //             taxId, unitPriceNoTax, totalSumNoTax, remarks, unitDescription, isWeightable, onAdd, isSumFlag, isSerialize
        //         } = this.props;

        //         let record = { key: keyVal, keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, taxPercentageValue, taxId, unitPriceNoTax, totalSumNoTax, remarks, unitDescription, isWeightable };

        //         if (isSumFlag && isSerialize != '3') this.addAndMerge(record);
        //         else if (itemCode) onAdd(record);

        //         setTimeout(() => {
        //             let itemElement = document.getElementById("search_item_input_id");
        //             itemElement.focus()
        //             itemElement.select()

        //         }, 500);
        //     }
        // }
    }

    componentDidMount() {
        document.removeEventListener("keydown", this.keyboardListener/*.bind(this)*/);
        console.log("TableOptimized: TableRowCreate mounted");
        document.addEventListener("keydown", this.keyboardListener/*.bind(this)*/);
    }
    componentWillUnmount() {
        console.log("TableOptimized TableRowCreate un-mounted");
        document.removeEventListener("keydown", this.keyboardListener/*.bind(this)*/);
    }

    addAndMerge = (mRecord) => {
        const editedRecord = this.props.editedRecord;
        const last_record = [...this.props.data.document2.dataSource]
            .reverse()
            .find(i => {
                //console.log("rrrrrrrrrrr5: ", typeof (i.unitID), i.unitID, parseFloat(i.unitID), typeof (editedRecord.unitID), editedRecord.unitID, parseFloat(editedRecord.unitID));

                return ((i.itemCode === editedRecord.itemCode)
                    && (parseFloat(i.discountPercentage) === parseFloat(editedRecord.discountPercentage))
                    && (parseFloat(i.unitPriceWithTax) === parseFloat(editedRecord.unitPriceWithTax))
                    //&& (parseFloat(i.unitID) === parseFloat(editedRecord.unitID))
                    && parseInt((i.unitID) ? i.unitID : 0) === parseInt((editedRecord.unitID) ? editedRecord.unitID : 0)
                )
            });

        let salesDoc = [1, 2, 22].filter(f => f == this.props.documentType).length > 0;
        let isSerializeModalOpen = salesDoc ? this.props.isSerialize > 0 : this.props.isSerialize == '3';

        if (last_record && this.props.isSumFlag && !isSerializeModalOpen) {
            let emptyRecord: RecordObj = {
                key: 0,
                keyVal: 0,
                itemCode: '',
                description: '',
                quantity: 0.00,
                unitPriceWithTax: 0.00,
                discountPercentage: 0.00,
                totalSumWithTax: 0.00,

                unitID: null, //_Size1Desc,
                quantityInUnit: 1.00,
                unitQuantity: 1.00,
                unitDescription: '', //`${getSizeDescription(_ItemSize1ID)}(${_q})`, //`$DESC(${_q})`,
                isWeightable: 0,
                costCurrencyId: 0,
                costCurrencySymbol: '',
                costCurrencyRate: 0,
                costCurrencyValue: 0
            };

            // 22355010002
            this.props.onUpdateData({
                ...last_record,
                unitQuantity: parseFloat(last_record.unitQuantity) + parseFloat(editedRecord.unitQuantity), //1.00,
                quantity: parseFloat(last_record.quantity) + parseFloat(last_record.quantityInUnit) * parseFloat(editedRecord.unitQuantity), //parseFloat(last_record.quantity) + 1.00,
                //unitPriceWithTax: parseFloat(editedRecord.price).toFixed(2), //_unitCost,//1,
                //unitPriceNoTax: parseFloat(editedRecord.priceNoTax).toFixed(2), //_unitCost,//1,
                //discountPercentage: 0,
                totalSumWithTax: (last_record.unitPriceWithTax
                    * (parseFloat(last_record.quantity) + parseFloat(last_record.quantityInUnit) * parseFloat(editedRecord.unitQuantity))
                    * (1 - parseFloat(last_record.discountPercentage) / 100)).toFixed(4),
                totalSumNoTax: (last_record.unitPriceNoTax
                    * (parseFloat(last_record.quantity) + parseFloat(last_record.quantityInUnit) * parseFloat(editedRecord.unitQuantity))
                    * (1 - parseFloat(last_record.discountPercentage) / 100)).toFixed(4),
                //taxId: editedRecord.taxId,
                //taxPercentageValue: editedRecord.taxPercentageValue,
            })
            this.onRecordChange(emptyRecord);
        } else {
            this.props.onAdd(mRecord);
        }
    }

    getInputNumberEdited = (value, field, record, tabIndex, moreAttributes, readOnly) => {
        let props = {
            style: { width: '100%', direction: "rtl" },
            value,
            precision: 4,
            readOnly,
            tabIndex: readOnly ? '-1' : tabIndex,
            onChange: (e) => {
                const { config, calculateRecord, costCalcOption } = this.props;
                let newRecord = calculateRecord(field, e, config.isUnitCost && costCalcOption == '1' && value != e);
                this.onRecordChange(record, newRecord)
            }
        }

        if (field == 'quantity') {

            const { documentType } = this.props
            props = {
                ...props,
                onBlur: () => {
                    if ((documentType == 9 || documentType == 21 || documentType == 22) && value > 0) {
                        Modal.confirm({
                            content: this.getText(19543),
                            okText: this.getText(19544),
                            cancelText: this.getText(19545),
                            onOk: () => { props.onChange(value * -1) },
                            onCancel() { },
                        })
                    }

                    this.setState({
                        openModalOnBlurQty: true,
                    })
                }
            }
        }

        if (moreAttributes) props = { ...props, ...moreAttributes }
        return (<InputNumber {...props} />)
    }

    getInputEdited = (value, field, record, tabIndex, moreAttributes) => {
        let xmlSplit = value ? value.split('>') : [];
        let xmlValue = '';
        if (xmlSplit.length > 1) {
            value = xmlSplit.splice(-1)[0];
            xmlValue = xmlSplit.join('>') + '>';
        }

        let props = {
            style: { width: '100%' },
            value,
            tabIndex,
            onChange: (e) => {
                if (field == "remarks" && e.target.value.length > 800) message.error(this.getText(19706))
                else this.onChange(record, field, xmlValue + e.target.value)
            }
        }
        if (moreAttributes) props = { ...props, ...moreAttributes }
        return (<Input {...props} />)
    }



    render() {
        console.log("render TableRowCreate")
        console.log("disabledAddItem", this.props.disabledAddItem)

        const { keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, taxPercentageValue, taxId, unitPriceNoTax, totalSumNoTax, remarks,
            unitID, quantityInUnit, unitQuantity, unitDescription, isWeightable, costCurrencyId, costCurrencySymbol, costCurrencyRate, costCurrencyValue, serialNumber, isSerialize, bonusCalcData
        } = this.props;
        const { onDataSourceChange, onEditedRecordChange, columns, editedRecord, onItemSearchChangeSearch, getSizeDescription } = this.props
        const { onAdd, onUpdate, onAddMerge, documentType } = this.props;
        //const { isCtrlB, buffer, isBufferFull, onCtrlBChange, onBufferChange } = this.props;
        const isSumFlag = this.props.isSumFlag;
        //const isCostWithTax = this.props.isCostWithTax;
        const { isCostWithTax, isCostNoTax, config, calculateRecord, costCalcOption } = this.props;
        const isCost = config.isUnitCost;

        let record = {
            key: keyVal, keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, taxPercentageValue, taxId, unitPriceNoTax, totalSumNoTax, remarks,
            unitID, quantityInUnit, unitQuantity, unitDescription, isWeightable, costCurrencyId, costCurrencySymbol, costCurrencyRate, costCurrencyValue, serialNumber, bonusCalcData
        };
        let inputNumberStyle = { width: '100%' };


        //console.log("vvvvvvvv isWeightable: ", isWeightable);
        console.log("vvvvvvvv record: ", record);

        const raiseAsycCallsFlag = () => {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.setJson,
                args: ["document2", {
                    ongoingRequests: (this.props.data.document2.ongoingRequests)
                        ? this.props.data.document2.ongoingRequests + 1
                        : 1
                }]
            });
        };
        const lowerAsycCallsFlag = () => {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.setJson,
                args: ["document2", {
                    ongoingRequests: (this.props.data.document2.ongoingRequests)
                        ? this.props.data.document2.ongoingRequests - 1 : 0
                }]
            });
        };

        const selectorItemsElement = () => {
            return (
                <SelectorItems
                    doFocus={true}
                    inputTabIndex={"2"}
                    user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                    isExpandMatrixFatherItem={true}
                    //itemType={2}
                    //value={Jax.get(this.props.data, "document.editedRecord.lines.editedRecord.itemCode", "")}
                    itemType={6}
                    value={itemCode}

                    onChangeSearch={(selection) => {
                        console.log("SelectorItems: onChangeSearch");
                        /** fetch, save change and check itemcode */
                        if (selection.mIsMatrixFather === '1') {
                            let modalParamObj = { ...this.props.ui.data, mCode: selection.mCode };
                            this.props.uiActions.showModal(TableIDs.modalMatrixQuantities, modalParamObj);

                        }
                        else {
                            onEditedRecordChange(
                                { ...record, itemCode: selection.mCode },
                                () => {
                                    this.props.onBlurItemInput({
                                        target: { value: selection.mCode },
                                        callback: () => {
                                            document.getElementById("quantity_input"/*"first_input"*/).focus()
                                            clearTimeout(timeoutSelectFocus)
                                            timeoutSelectFocus = setTimeout(() => { document.getElementById("quantity_input"/*"first_input"*/).select() }, 400);
                                        }
                                    })
                                }
                            );
                        }
                    }}
                    onChange={(selection) => {
                        console.log("SelectorItems: onChange");
                        /** fetch, save change and check itemcode */
                        if (this.isCtrlB === 0) {
                            this.props.setDisabledAddItem(true)
                            onEditedRecordChange({
                                ...record,
                                itemCode: selection.target.value,
                            });
                        } else if (this.isCtrlB === 1) {
                            this.buffer = this.buffer + selection.target.value;
                        }
                        else { // this.isCtrl === 2
                            //onEditedRecordChange({ ...record, itemCode: selection.target.value/*selection.mCode*/ });
                            console.log("onChange 2: ", this.isCtrlB);
                        }
                        // focuse on selector text
                        document.getElementById("search_item_input_id"/*"first_input"*/).focus();
                    }}

                    onBlur={s => { this.props.onBlurItemInput(s) }}
                    onKeyDown={(e) => {
                        if (!this.props.ui.modalStack.includes(TableIDs.modalDocumentSelector) && !this.props.scanDocOpen && this.isCtrlB) {

                            if (/*e.keyCode === "keyM" ||*/ e.key === "Enter" || this.isCtrlB === 2) {
                                console.log("SelectorItems: onKeyDown");
                                this.checkItemAndDo2(this.buffer);
                                this.isCtrlB = 0;
                                this.buffer = '';
                            }
                        }
                    }}

                    supplierId={this.props.data.document2.supplierCode}
                    supplierName={this.props.data.document2.supplierName}
                    filterPerSupplier={this.props.data.document2.filterItemsPerSupplier == '1'}
                    onLoadDataSource={this.props.onLoadSelectorItems}
                    dataSource2={this.props.selectorItemsDataSource2}
                    columns2={this.props.selectorItemsColumns2}
                />)
        }

        let _Size1 = decodeXml(remarks, 'Size1');
        let _color = decodeXml(remarks, 'Color');
        let isMatrix = !!(_color || _Size1);

        let unitSizeList = Jax.get(this.props.data, "UOM_Ruler.data", []).map((obj, index) => {
            return (<Option style={{ direction: 'rtl' }} key={obj['sizeID']} value={obj['sizeID']/*obj['sizeUOMQty']|*/}
                valuefilter={obj['sizeID'] + ' ' + obj['sizeDesc']}>{/*obj['sizeID'] + ' - ' + */obj['sizeDesc'] + ' - ' + obj['sizeUOMQty'] + ' ' + this.getText(18191)}
            </Option>)
        });

        let descriptionInput = this.getInputEdited(description, 'description', record, '3');
        let remarkInput = this.getInputEdited(remarks, 'remarks', record, (this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax && isCostWithTax ? '10' : '9' : isCostNoTax && isCostWithTax ? "9" : "8");

        let qtyInput = this.getInputNumberEdited(quantity /*quantityInUnit*/, 'quantity', record, '4',
            {
                id: 'quantity_input',
                // max: (this.props.documentType == 22 || this.props.documentType == 9 /*|| this.props.documentType == 17*/) ? -1 : 999999, 
                max: 999999,
                precision: 4, step: 1,
                onFocus: () => {
                    clearTimeout(timeoutSelectFocus)
                    timeoutSelectFocus = setTimeout(() => { document.getElementById("quantity_input").select() }, 400)
                }
            },
            record.quantityInUnit > 1 || record.serialNumber);

        // let unitInput = this.getInputNumberEdited(
        //     // !isCostWithTax && isCost ? unitPriceNoTax : unitPriceWithTax,
        //     !isCostWithTax ? unitPriceNoTax : unitPriceWithTax,
        //     // !isCostWithTax && isCost ? 'unitPriceNoTax' : 'unitPriceWithTax',
        //     !isCostWithTax ? 'unitPriceNoTax' : 'unitPriceWithTax',
        //     record,
        //     (this.props.documentType == 4 || this.props.documentType == 18) ? '6' : '5',
        //     {
        //         id: "price_input",
        //         onFocus: () => {
        //             clearTimeout(timeoutSelectFocus)
        //             timeoutSelectFocus = setTimeout(() => { document.getElementById("price_input").select() }, 400)
        //         }
        //     }
        // );

        let unitInputNoTax = this.getInputNumberEdited(
            unitPriceNoTax, 'unitPriceNoTax', record,
            (this.props.documentType == 4 || this.props.documentType == 18) ? '6' : '5',
            {
                id: "price_input_no_tax",
                onFocus: () => {
                    clearTimeout(timeoutSelectFocus)
                    timeoutSelectFocus = setTimeout(() => { document.getElementById("price_input_no_tax").select() }, 400)
                },
                readOnly: isCost&& costCalcOption == '1',
            }
        );

        let unitInputWithTax = this.getInputNumberEdited(
            unitPriceWithTax, 'unitPriceWithTax', record,
            (this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax ? '7' : '6' : isCostNoTax ? "6" : "5",
            {
                id: "price_input_with_tax",
                precision: 2,
                onFocus: () => {
                    clearTimeout(timeoutSelectFocus)
                    timeoutSelectFocus = setTimeout(() => { document.getElementById("price_input_with_tax").select() }, 400)
                },
                readOnly: isCost&& costCalcOption == '1',
            }
        );


        let unitCurrencyInput = (this.props.documentType == 4 || this.props.documentType == 18) ?
            this.getInputNumberEdited(costCurrencyValue, 'costCurrencyValue', record, '5', {readOnly: isCost&& costCalcOption == '1'}, !(costCurrencyId > 1))
            : ""

        let cSymbol = <div style={{ textAlign: 'center', background: '#ffffff', height: 30, borderRadius: 3, paddingTop: 4, marginTop: 1 }}>{costCurrencySymbol}</div>

        let discountInput = isCost && costCalcOption == '1' ?
            <ModalBonusCulc
                disableDscnt3
                openModalOnBlurQty={this.state.openModalOnBlurQty}
                onClose={() => { this.setState({ openModalOnBlurQty: false }) }}
                modalData={this.props.data.document2.editedRecord.bonusCalcData}
                handleOk={bonusCalcData => {
                    this.onRecordChange(record, { ...this.props.calculateRecord("unitPriceNoTax", bonusCalcData.mCostIncludeDscnt), bonusCalcData })
                }} />
            : this.getInputNumberEdited(discountPercentage, 'discountPercentage', record,
                (this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax && isCostWithTax ? '8' : '7' : isCostNoTax && isCostWithTax ? "7" : "6",
                { min: 0.00, max: 100, step: 0.01, precision: 2 });

        let sumInput = this.getInputNumberEdited(totalSumNoTax, "totalSumNoTax",
            // !isCostWithTax && isCost ? totalSumNoTax : totalSumWithTax,
            // !isCostWithTax ? totalSumNoTax : totalSumWithTax,
            // !isCostWithTax && isCost ? 'totalSumNoTax' : 'totalSumWithTax',
            // !isCostWithTax ? 'totalSumNoTax' : 'totalSumWithTax',
            record, (this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax && isCostWithTax ? '9' : '8' : isCostNoTax && isCostWithTax ? "8" : "7",
            {
                id: 'last_input',
                onFocus: () => {
                    clearTimeout(timeoutSelectFocus)
                    timeoutSelectFocus = setTimeout(() => { document.getElementById("last_input").select() }, 400)
                },
                readOnly: isCost&& costCalcOption == '1',
                ...this.setAttributeRangeBySign(quantity, unitPriceNoTax)
            });

        // let plusIcon = (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"plus"} onClick={(e) => {
        const onSaveRow = () => {
            if (!this.props.disabledAddItem && itemCode) {
                let salesDoc = [1, 2, 22].filter(f => f == this.props.documentType).length > 0;
                let isSerializeModalOpen = salesDoc ? isSerialize > 0 : isSerialize == '3';

                console.log("disabledAddItem itemCode ", itemCode, description)
                if (isSumFlag && !isSerializeModalOpen) this.addAndMerge(record);
                else onAdd(record);
                if (!isSerializeModalOpen) {
                    setTimeout(() => {
                        document.getElementById("search_item_input_id"/*"first_input"*/).focus();
                    }, 1000);
                }
            }
        }

        let plusIcon = (<Button style={{ fontSize: 16 }} className={"clickable margin-4"} onClick={onSaveRow} >{this.getText(18628)}</Button>)

        return (
            <React.Fragment >
                <div id="focusguard-1" tabIndex="1" onFocus={() => { document.getElementById("last_input").focus() }}></div>
                {(window.innerWidth > 600) ?
                    (<React.Fragment>
                        <tr style={{ backgroundColor: '#d5dfed' }} /*style={{ backgroundColor: '#e6ffff' }}*/ disabled={keyVal && keyVal !== 0}
                            onKeyPress={(e) => { if (e.key == "Enter") onSaveRow() }}>
                            <td >{keyVal}</td>
                            <td colSpan={2}>{selectorItemsElement()}</td>
                            <td colSpan={(isMatrix) ? 1 : 3}>{descriptionInput}</td>
                            {(isMatrix) ? (<React.Fragment><td >{_Size1}</td><td>{_color}</td></React.Fragment>) : null}

                            <td colSpan={2}>
                                <div id="unit_size_selector" tabIndex={unitSizeList.length ? "14" : "-1"}>
                                    {unitSizeList.length ?
                                        <GenericSelector
                                            modalStyle={{
                                                title: this.getText(18629),
                                                col1Title: this.getText(18630),
                                                col2Title: this.getText(18631),
                                                cancelText: this.getText(18632),
                                                clearText: this.getText(18633),
                                            }}
                                            dataset={Jax.get(this.props.data, "UOM_Ruler.data", []).map(obj => { return { ...obj, code: obj['sizeID'], name: obj['sizeDesc'] } })}
                                            value={unitID}
                                            onChange={() => { }}
                                            onChangeGetDetailed={(res) => {
                                                if (res) {
                                                    this.onRecordChange({ ...record, unitID: res.sizeID, unitQuantity: 1, quantityInUnit: res.sizeUOMQty },
                                                        calculateRecord('unitID', res.sizeID/*res.sizeUOMQty*/)
                                                    )
                                                }
                                            }}
                                            placeholder={"יח' מידה"}
                                        />
                                        : <Input readOnly />}
                                </div>
                                {/* <Select
                                    id="unit_size_selector"
                                    tabIndex={unitSizeList.length ? "4" : "-1"}
                                    readOnly={!unitSizeList.length}
                                    onChange={(val) => {
                                        let res = this.props.data['UOM_Ruler'].data.find(i => {
                                            return (i.sizeID).toString() === val.toString()
                                        }
                                        );
                                        //{sizeID: "46", sizeDesc: "יחידה", sizeUOMQty: "1", itemRuler1ID: "16"}
                                        if (res) {
                                            this.onRecordChange({ ...record, unitID: res.sizeID, unitQuantity: 1, quantityInUnit: res.sizeUOMQty },
                                                calculateRecord('unitID', res.sizeID/*res.sizeUOMQty))
                                        }
                                    }}
                                    placeholder={"יח' מידה"}
                                    value={unitID}
                                    style={{ width: "96%", marginRight: "4px" }}
                                //notFoundContent={this.getText(12528)}
                                //disabled={parseInt(this.props.documentType, 10) === 4}
                                >
                                    {unitSizeList}
                                </Select> */}
                            </td>

                            <td>
                                <InputNumber //precision={3}
                                    // max={(this.props.documentType == 22 || this.props.documentType == 9 /*|| this.props.documentType == 17*/) ? -1 : 999999}
                                    // דניאל ביקש לשנות 29.10.2023
                                    max={999999}
                                    style={inputNumberStyle}
                                    tabIndex={quantityInUnit > 1 ? "4" : "-1"}
                                    readOnly={quantityInUnit == 1}
                                    id="unit_quantity_input"
                                    value={unitQuantity}
                                    onChange={(e) => {
                                        if ((!this.isBigQuantityAllowed || this.isBigQuantityAllowed === 0)
                                            && (parseFloat(e) > 99999.00 || parseFloat(e) < -99999.00)) {
                                            // big num
                                            notification.warning({
                                                key: 'isBigQuantityAllowed',
                                                message: 'כמות חריגה לפריט',
                                                //description: (<div>{`? ${keyVal} האם לאפשר הכנסת כמות חריגה לרשומה`}</div>),
                                                description: (<div>{`? האם לאפשר הכנסת כמות חריגה לרשומה`}</div>),
                                                placement: 'topLeft',
                                                duration: 0,
                                                btn: (<Button type="primary" size="small" onClick={() => {
                                                    notification.close('isBigQuantityAllowed');
                                                    this.isBigQuantityAllowed = 1;
                                                    this.onRecordChange(record, calculateRecord('unitQuantity', e)/*{ quantity: e }*/)
                                                }}>אישור</Button>)
                                            });
                                        } else {
                                            if (parseFloat(e) > 99999999.00 || parseFloat(e) < -99999999.00) {
                                                if (parseFloat(e) > 99999999.00) {
                                                    this.onRecordChange(record, calculateRecord('unitQuantity', 99999999.00)/*{ quantity: e }*/)
                                                } else {
                                                    this.onRecordChange(record, calculateRecord('unitQuantity', -99999999.00)/*{ quantity: e }*/)
                                                }
                                            } else {
                                                this.onRecordChange(record, calculateRecord('unitQuantity', e)/*{ quantity: e }*/)
                                            }
                                        }
                                    }} />
                            </td>

                            <td>{qtyInput}</td>
                            {this.props.documentType == 4 || this.props.documentType == 18 ?
                                <td>
                                    <Row>
                                        <Col span={20}>{unitCurrencyInput}</Col>
                                        <Col span={4}>{cSymbol}</Col>
                                    </Row>
                                </td>
                                : ""}
                            <td> {isCostNoTax ? unitInputNoTax : unitInputWithTax}</td>
                            {isCostNoTax && isCostWithTax ? <td>{unitInputWithTax}</td> : ""}
                            <td>{discountInput}</td>
                            <td>{sumInput}</td>
                            <td>{plusIcon}</td>
                        </tr>
                        <tr>
                            <td colSpan={3}>{this.getText(15396)}</td>
                            <td colSpan={(this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax && isCostWithTax ? 12 : 11 : isCostNoTax && isCostWithTax ? 11 : 10}>{remarkInput}</td>
                        </tr>
                    </React.Fragment>)
                    : (<React.Fragment>
                        <tr>
                            <td>{this.getText(12513)}</td>
                            <td>{keyVal}</td>
                            <td rowspan={(this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax && isCostWithTax ? "10" : "9" : isCostNoTax && isCostWithTax ? "9" : "8"}>{plusIcon}</td>
                        </tr>
                        <tr>
                            <td>{this.getText(12514)}</td>
                            <td>{selectorItemsElement()}</td>
                        </tr>
                        <tr>
                            <td>{this.getText(12515)}</td>
                            <td>{descriptionInput}</td>
                        </tr>
                        <tr>
                            <td>{this.getText(12515)}</td>
                            <td>{descriptionInput}</td>
                        </tr>
                        <tr>
                            <td>{this.getText(12516)}</td>
                            <td>{qtyInput}</td>
                        </tr>
                        {this.props.documentType == 4 || this.props.documentType == 18 ?
                            <tr>
                                <td>{this.getText(18192)}</td>
                                <td>
                                    <Row>
                                        <Col span={20}>{unitCurrencyInput}</Col>
                                        <Col span={4}>{cSymbol}</Col>
                                    </Row>
                                </td>
                            </tr>
                            : ""}
                        {isCostNoTax ?
                            <tr>
                                <td>{this.getText(12517)}</td>
                                <td>{unitInputNoTax}</td>
                            </tr>
                            : ""}
                        {isCostWithTax ?
                            <tr>
                                <td>{this.getText(18627)}</td>
                                <td>{unitInputWithTax}</td>
                            </tr>
                            : ""}
                        <tr>
                            <td>{this.getText(12518)}</td>
                            <td>{discountInput}</td>
                        </tr>
                        <tr>
                            <td>{this.getText(12519)}</td>
                            <td>{sumInput}</td>
                        </tr>
                        <tr>
                            <td>{this.getText(15396)}</td>
                            <td>{remarkInput}</td>
                        </tr>
                    </React.Fragment>)
                }

                <div id="focusguard-2" tabIndex={(this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax && isCostWithTax ? "10" : "9" : isCostNoTax && isCostWithTax ? "9" : "8"} onFocus={() => {
                    this.isBigQuantityAllowed = 0;
                    if (isSumFlag && isSerialize != '3') {
                        this.addAndMerge(record);
                    } else {
                        console.log("onAdd record (tab): ", record);
                        if (itemCode) {
                            onAdd(record);
                        }
                    }
                    document.getElementById("search_item_input_id"/*"first_input"*/).focus();
                }}></div>
            </React.Fragment >
        )
    }
}

// ########################################
class TableRowEditable extends PureComponent/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.buffer = "";
        this.isCtrlB = false;
        this.state = {
            openModalOnBlurQty: false,
        }
    }

    static defaultProps = {
        keyVal: 0,
        itemCode: null,
        itemBarcode: null,
        description: null,
        quantity: 0.00,
        unitPriceWithTax: null,
        discountPercentage: null,
        totalSumWithTax: null,

        unitPriceNoTax: null,
        totalSumNoTax: null,
        isWeightable: 0,
    }

    setAttributeRangeBySign = (_quantity, _unitPrice) => {
        return (parseFloat(_unitPrice) === 0)
            ? { min: 0, max: 0 }
            : (_quantity > 0) ? { min: 0 } : { max: 0 }
    }

    onChange = (record, field, value) => {
        this.props.onEditedRecordChange({ ...record, [field]: value });
    }
    onRecordChange = (record, changesObj) => {
        this.props.onEditedRecordChange({ ...record, ...changesObj });
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    getUOMqty = (_IsMatrixReverseFather, _UOM_Qty): Number => {
        return (_IsMatrixReverseFather && (_IsMatrixReverseFather === 1 || _IsMatrixReverseFather === '1')) ? _UOM_Qty * 1.00 : 1.00;

    }

    getInputNumberEdited = (value, field, record, tabIndex, moreAttributes, readOnly) => {
        let props = {
            style: { width: '100%', direction: "rtl" },
            value,
            precision: 4,
            readOnly,
            tabIndex: readOnly ? '-1' : tabIndex,
            onChange: (e) => {
                //  if (!(field === 'quantity' && record.quantityInUnit > 1))
                const { config, calculateRecord, costCalcOption } = this.props;
                let newRecord = calculateRecord(field, e, config.isUnitCost && costCalcOption == '1' && value != e);
                this.onRecordChange(record, newRecord)
            }
        }

        if (field == 'quantity') {
            const { documentType } = this.props
            props = {
                ...props,
                onBlur: () => {
                    if ((documentType == 9 || documentType == 21 || documentType == 22) && value > 0) {
                        Modal.confirm({
                            content: this.getText(19543),
                            okText: this.getText(19544),
                            cancelText: this.getText(19545),
                            onOk: () => { props.onChange(value * -1) },
                            onCancel() { },
                        })
                    }

                    this.setState({
                        openModalOnBlurQty: true,
                    })
                }
            }
        }

        if (moreAttributes) props = { ...props, ...moreAttributes }
        return (<InputNumber {...props} />)
    }

    getXmlValue = (v) => {
        let value = v;
        let xmlSplit = v ? v.split('>') : [];
        let xmlValue = '';
        if (xmlSplit.length > 1) {
            value = xmlSplit.splice(-1)[0];
            xmlValue = xmlSplit.join('>') + '>';
        }
        return { value, xmlValue }
    }

    getInputEdited = (v, field, record, tabIndex, moreAttributes) => {
        const { value, xmlValue } = this.getXmlValue(v);
        let props = {
            style: { width: '100%' },
            value,
            tabIndex,
            onChange: (e) => {
                if (field == "remarks" && e.target.value.length > 800) message.error(this.getText(19706))
                else this.onChange(record, field, xmlValue + e.target.value)
            }
        }
        if (moreAttributes) props = { ...props, ...moreAttributes }
        if (tabIndex == '13') props = { ...props, autoFocus: true }
        return (<Input {...props} />)
    }

    render() {
        console.log("render TableRowEditable")
        const { keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, taxPercentageValue, taxId, unitPriceNoTax, totalSumNoTax, remarks,
            unitID, quantityInUnit, unitQuantity, unitDescription, isWeightable, costCurrencyId, costCurrencySymbol, costCurrencyRate, costCurrencyValue, serialNumber,
            onDataSourceChange, onEditedRecordChange, columns, editedRecord, onItemSearchChangeSearch, getSizeDescription,
            onAdd, onEdit, onCancel, onUpdate, documentType,
            isCostNoTax, isCostWithTax, config, calculateRecord, costCalcOption, bonusCalcData
        } = this.props;
        const isCost = config.isUnitCost;


        let record = {
            key: keyVal, keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, taxPercentageValue, taxId, unitPriceNoTax, totalSumNoTax, remarks,
            unitID, quantityInUnit, unitQuantity, unitDescription, isWeightable, costCurrencyId, costCurrencySymbol, costCurrencyRate, costCurrencyValue, serialNumber, bonusCalcData
        };
        let inputNumberStyle = { width: '100%' };

        const selectorItemsElement = () => {
            return (<SelectorItems
                // doFocus={true}
                inputTabIndex={"12"}
                user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                isExpandMatrixFatherItem={true}
                itemType={6}
                //itemType={2}
                //value={Jax.get(this.props.data, "document.editedRecord.lines.editedRecord.itemCode", "")}
                value={itemCode}
                onChangeSearch={(selection) => {
                    /** fetch, save change and check itemcode */
                    if (selection.mIsMatrixFather === '1') {
                        let modalParamObj = { ...this.props.ui.data, mCode: selection.mCode };
                        this.props.uiActions.showModal(TableIDs.modalMatrixQuantities, modalParamObj);
                    }
                    else {
                        onEditedRecordChange(
                            { ...record, itemCode: selection.mCode },
                            (r) => { setTimeout(function () { document.getElementById("search_item_input_id"/*"first_input"*/).focus() }, 400) }
                        );
                    }
                }}
                onChange={(selection) => {
                    /** fetch, save change and check itemcode */
                    if (this.isCtrlB === true /* && this.isBufferFull === false*/) {
                        this.barcodeReaderBuffer = this.barcodeReaderBuffer + selection.target.value;
                    } else {
                        this.props.setDisabledAddItem(true)
                        if (this.isBufferFull === true) {
                            onEditedRecordChange({
                                ...record,
                                itemCode: this.barcodeReaderBuffer.toString(),
                            });
                            this.barcodeReaderBuffer = '';
                            this.barcodeReaderBuffer = this.barcodeReaderBuffer + selection.target.value;
                            onEditedRecordChange({ ...record, itemCode: '' });
                        } else {
                            onEditedRecordChange({
                                ...record,
                                itemCode: selection.target.value,
                            });

                        }
                    }

                }}

                onBlur={s => { this.props.onBlurItemInput(s) }}
                onKeyDown={(e) => { }}
                supplierId={this.props.data.document2.supplierCode}
                supplierName={this.props.data.document2.supplierName}
                filterPerSupplier={this.props.data.document2.filterItemsPerSupplier == '1'}
                onLoadDataSource={this.props.onLoadSelectorItems}
                dataSource2={this.props.selectorItemsDataSource2}
                columns2={this.props.selectorItemsColumns2}
            />)
        }

        console.log("record: ", record);
        // console.log(record);
        let _Size1 = decodeXml(remarks, 'Size1');
        let _color = decodeXml(remarks, 'Color');
        let isMatrix = !!(_color || _Size1);

        //const getUOMSizesRuler
        let unitSizeList = Jax.get(this.props.data, "UOM_Ruler.data", []).map((obj, index) => {
            return (<Option style={{ direction: 'rtl' }} key={obj['sizeID']} value={obj['sizeID']/*obj['sizeUOMQty']|*/}
                valuefilter={obj['sizeID'] + ' ' + obj['sizeDesc']}>{/*obj['sizeID'] + ' - ' + */obj['sizeDesc'] + ' - ' + obj['sizeUOMQty'] + ' ' + this.getText(18191)}
            </Option>)
        });

        let descriptionInput = this.getInputEdited(description, 'description', record, '13');
        let remarkInput = this.getInputEdited(remarks, 'remarks', record, (this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax && isCostWithTax ? '20' : '19' : isCostNoTax && isCostWithTax ? "19" : "18");

        let qtyInput = this.getInputNumberEdited(quantity/*quantityInUnit*/, 'quantity', record, '14',
            {
                id: "quantity_input",
                // max: (this.props.documentType == 22 || this.props.documentType == 9 /*|| this.props.documentType == 17*/) ? -1 : 999999, 
                // דניאל ביקש לשנות 29.10.2023
                max: 999999,
                precision: 4, step: 1,
                onFocus: () => {
                    clearTimeout(timeoutSelectFocus)
                    timeoutSelectFocus = setTimeout(() => { document.getElementById("quantity_input").select() }, 400)
                }
            },
            record.quantityInUnit > 1 || record.serialNumber);

        // let unitInput = this.getInputNumberEdited(!isCostWithTax && isCost ? unitPriceNoTax : unitPriceWithTax, !isCostWithTax && isCost ? 'unitPriceNoTax' : 'unitPriceWithTax', record, '15');
        // let unitInput = this.getInputNumberEdited(!isCostWithTax ? unitPriceNoTax : unitPriceWithTax, !isCostWithTax ? 'unitPriceNoTax' : 'unitPriceWithTax', record,
        //     (this.props.documentType == 4 || this.props.documentType == 18) ? '16' : '15',
        //     {
        //         id: "price_input",
        //         onFocus: () => {
        //             clearTimeout(timeoutSelectFocus)
        //             timeoutSelectFocus = setTimeout(() => { document.getElementById("price_input").select() }, 400)
        //         }
        //     });

        let unitInputNoTax = this.getInputNumberEdited(
            unitPriceNoTax, 'unitPriceNoTax', record,
            (this.props.documentType == 4 || this.props.documentType == 18) ? '16' : '15',
            {
                id: "price_input_no_tax",
                onFocus: () => {
                    clearTimeout(timeoutSelectFocus)
                    timeoutSelectFocus = setTimeout(() => { document.getElementById("price_input_no_tax").select() }, 400)
                },
                readOnly: isCost&& costCalcOption == '1'
            }
        );

        let unitInputWithTax = this.getInputNumberEdited(
            unitPriceWithTax, 'unitPriceWithTax', record,
            (this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax ? '17' : '16' : isCostNoTax ? '16' : '15',
            {
                id: "price_input_with_tax",
                precision: 2,
                onFocus: () => {
                    clearTimeout(timeoutSelectFocus)
                    timeoutSelectFocus = setTimeout(() => { document.getElementById("price_input_with_tax").select() }, 400)
                },
                readOnly: isCost&& costCalcOption == '1',
            }
        );
        let unitCurrencyInput = (this.props.documentType == 4 || this.props.documentType == 18) ?
            this.getInputNumberEdited(costCurrencyValue, 'costCurrencyValue', record, '15', {readOnly: isCost&& costCalcOption == '1'}, !(costCurrencyId > 1))
            : "";
        let cSymbol = <div style={{ textAlign: 'center', background: '#ffffff', height: 30, borderRadius: 3, paddingTop: 4, marginTop: 1 }}>{costCurrencySymbol}</div>

        let discountInput = isCost && costCalcOption == '1' ?
            <ModalBonusCulc
                disableDscnt3
                openModalOnBlurQty={this.state.openModalOnBlurQty}
                onClose={() => { this.setState({ openModalOnBlurQty: false }) }}
                modalData={this.props.data.document2.editedRecord.bonusCalcData}
                handleOk={bonusCalcData => {
                    this.onRecordChange(record, { ...this.props.calculateRecord("unitPriceNoTax", bonusCalcData.mCostIncludeDscnt), bonusCalcData })
                }} />
            : this.getInputNumberEdited(discountPercentage, 'discountPercentage', record, (
                this.props.documentType == 4 || this.props.documentType == 18) ? '18' : '17', { min: 0.00, max: 100, step: 0.01, precision: 2 });

        let sumInput = this.getInputNumberEdited(totalSumNoTax, "totalSumNoTax",
            // !isCostWithTax && isCost ? totalSumNoTax : totalSumWithTax,
            // !isCostWithTax ? totalSumNoTax : totalSumWithTax,
            // !isCostWithTax && isCost ? 'totalSumNoTax' : 'totalSumWithTax',
            // !isCostWithTax ? 'totalSumNoTax' : 'totalSumWithTax',
            record, (this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax && isCostWithTax ? '19' : '18' : isCostNoTax && isCostWithTax ? '18' : '17',
            {
                id: 'last_input',
                onFocus: () => {
                    clearTimeout(timeoutSelectFocus)
                    timeoutSelectFocus = setTimeout(() => { document.getElementById("last_input").select() }, 400)
                },
                readOnly: isCost&& costCalcOption == '1',
                ...this.setAttributeRangeBySign(quantity, unitPriceNoTax)
            });

        let editIcons = [
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"} onClick={(e) => {
                // console.log("disabledAddItem", this.props.disabledAddItem )
                // if (!this.props.disabledAddItem) 
                setTimeout(() => { onUpdate(keyVal) }, 1000);  /*onAdd(record);*/
            }} />,
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"} onClick={(e) => { setTimeout(() => { onCancel(e) }, 1000); }} />
        ];


        return (<React.Fragment >
            {(window.innerWidth > 600)
                ? (<React.Fragment>
                    <tr>
                        <td>{keyVal}</td>
                        <td colSpan={2}>{selectorItemsElement()}</td>
                        <td colSpan={(isMatrix) ? 1 : 3}>{descriptionInput}</td>
                        {(isMatrix) ? (<React.Fragment><td >{_Size1}</td><td >{_color}</td></React.Fragment>) : null}
                        <td colSpan={2}>
                            <div id="unit_size_selector" tabIndex={unitSizeList.length ? "14" : "-1"}>
                                {unitSizeList.length ?
                                    <GenericSelector
                                        modalStyle={{
                                            title: this.getText(18629),
                                            col1Title: this.getText(18630),
                                            col2Title: this.getText(18631),
                                            cancelText: this.getText(18632),
                                            clearText: this.getText(18633),
                                        }}
                                        dataset={Jax.get(this.props.data, "UOM_Ruler.data", []).map(obj => { return { ...obj, code: obj['sizeID'], name: obj['sizeDesc'] } })}
                                        value={unitID}
                                        onChange={() => { }}
                                        onChangeGetDetailed={(res) => {
                                            if (res) {
                                                this.onRecordChange({ ...record, unitID: res.sizeID, unitQuantity: 1, quantityInUnit: res.sizeUOMQty },
                                                    calculateRecord('unitID', res.sizeID/*res.sizeUOMQty*/)
                                                )
                                            }
                                        }}
                                        placeholder={"יח' מידה"}
                                    />
                                    : <Input readOnly />}
                            </div>
                            {/* <Select
                                id="unit_size_selector"
                                tabIndex={unitSizeList.length ? "14" : "-1"}
                                readOnly={!unitSizeList.length}
                                onChange={(val) => {
                                    let res = this.props.data['UOM_Ruler'].data.find(i => {
                                        return (i.sizeID).toString() === val.toString()
                                    }
                                    );
                                    //{sizeID: "46", sizeDesc: "יחידה", sizeUOMQty: "1", itemRuler1ID: "16"}
                                    if (res) {
                                        this.onRecordChange({ ...record, unitID: res.sizeID, unitQuantity: 1, quantityInUnit: res.sizeUOMQty },
                                            //calculateRecord('unit_quantity', res.sizeUOMQty)
                                            calculateRecord('unitID', res.sizeID/*res.sizeUOMQty)
                                        )
                                    }
                                }}
                                placeholder={"יח' מידה"}
                                value={unitID}
                                style={{ width: "96%", marginRight: "4px" }}
                            //notFoundContent={this.getText(12528)}
                            //disabled={parseInt(this.props.documentType, 10) === 4}
                            >
                                {unitSizeList}
                            </Select> */}
                        </td>

                        <td >
                            <InputNumber
                                //  precision={3} /*precision={(isWeightable && (isWeightable === '1' || isWeightable === 1)) ? 3 : 0}*/
                                // max={(this.props.documentType == 22 || this.props.documentType == 9 /*|| this.props.documentType == 17*/) ? -1 : 999999}
                                // דניאל ביקש לשנות 29.10.2023
                                max={999999}
                                style={inputNumberStyle}
                                tabIndex={quantityInUnit > 1 ? "14" : "-1"}
                                readOnly={quantityInUnit == 1}
                                id="unit_quantity_input"
                                value={unitQuantity}
                                onChange={(e) => {

                                    if ((!this.isBigQuantityAllowed || this.isBigQuantityAllowed === 0)
                                        && (parseFloat(e) > 99999.00 || parseFloat(e) < -99999.00)) {
                                        // big num
                                        notification.warning({
                                            key: 'isBigQuantityAllowed',
                                            message: 'כמות חריגה לפריט',
                                            description: (<div>{`? ${keyVal} האם לאפשר הכנסת כמות חריגה לרשומה`}</div>),
                                            //description: (<div>{`? האם לאפשר הכנסת כמות חריגה לרשומה`}</div>),
                                            placement: 'topLeft',
                                            duration: 0,
                                            btn: (<Button type="primary" size="small" onClick={() => {
                                                notification.close('isBigQuantityAllowed');
                                                this.isBigQuantityAllowed = 1;
                                                this.onRecordChange(record, calculateRecord('unitQuantity', e)/*{ quantity: e }*/)
                                            }}>אישור</Button>)
                                        });
                                    } else {
                                        this.onRecordChange(record, calculateRecord('unitQuantity', e)/*{ quantity: e }*/)
                                    }

                                    //this.onRecordChange(record, calculateRecord('unitQuantity', e)/*{ quantity: e }*/)
                                }} />
                        </td>
                        {/* DEBUG unitID, quantityInUnit, unitQuantity*/}

                        <td>{qtyInput}</td>
                        {this.props.documentType == 4 || this.props.documentType == 18 ?
                            <td>
                                <Row>
                                    <Col span={20}>{unitCurrencyInput}</Col>
                                    <Col span={4}>{cSymbol}</Col>
                                </Row>
                            </td>
                            : ""}
                        <td>{isCostNoTax ? unitInputNoTax : unitInputWithTax}</td>
                        {isCostNoTax && isCostWithTax ? <td>{unitInputWithTax}</td> : ""}
                        <td>{discountInput}</td>
                        <td >{sumInput}</td>
                        <td>{editIcons}</td>
                    </tr>
                    <tr>
                        <td colSpan={3}>{this.getText(15396)}</td>
                        <td colSpan={(this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax && isCostWithTax ? 12 : 11 : isCostNoTax && isCostWithTax ? 11 : 10}>{remarkInput}</td>
                    </tr>
                </React.Fragment>)

                : (<React.Fragment>
                    <tr>
                        <td>{this.getText(12513)}</td>
                        <td>{keyVal}</td>
                        <td rowspan={(this.props.documentType == 4 || this.props.documentType == 18) ? isCostNoTax && isCostWithTax ? "10" : "9" : isCostNoTax && isCostWithTax ? "9" : "8"}>{editIcons}</td>
                    </tr>
                    <tr>
                        <td>{this.getText(12514)}</td>
                        <td>{selectorItemsElement()}</td>
                    </tr>
                    <tr>
                        <td>{this.getText(12515)}</td>
                        <td>{descriptionInput}</td>
                    </tr>
                    <tr>
                        <td>{this.getText(12516)}</td>
                        <td>{qtyInput}</td>
                    </tr>
                    {this.props.documentType == 4 || this.props.documentType == 18 ?
                        <tr>
                            <td>{this.getText(18192)}</td>
                            <td>
                                <Row>
                                    <Col span={20}>{unitCurrencyInput}</Col>
                                    <Col span={4}>{cSymbol}</Col>
                                </Row>
                            </td>
                        </tr>
                        : ""}
                    {isCostNoTax ?
                        <tr>
                            <td>{this.getText(12517)}</td>
                            <td>{unitInputNoTax}</td>
                        </tr>
                        : ""}
                    {isCostWithTax ?
                        <tr>
                            <td>{this.getText(18627)}</td>
                            <td>{unitInputWithTax}</td>
                        </tr>
                        : ""}
                    <tr>
                        <td>{this.getText(12518)}</td>
                        <td>{discountInput}</td>
                    </tr>
                    <tr>
                        <td>{this.getText(12519)}</td>
                        <td>{sumInput}</td>
                    </tr>
                    <tr>
                        <td>{this.getText(15396)}</td>
                        <td>{remarkInput}</td>
                    </tr>
                </React.Fragment>)

            }
            <div id="focusguard-e2" tabIndex="19" onFocus={() => {
                this.isBigQuantityAllowed = 0;
                onUpdate(keyVal); /*document.getElementById("first_input").focus()*/
            }}></div>
        </React.Fragment>
        )
    }

}

class TableRow extends PureComponent/*<Props, State>*/ {
    static defaultProps = {
        keyVal: 0,
        itemCode: null,
        itemBarcode: null,
        isValid: 0,
        description: null,
        quantity: 0,
        unitPriceWithTax: null,
        discountPercentage: null,
        totalSumWithTax: null,

        unitPriceNoTax: null,
        totalSumNoTax: null,

        unitID: null,
        quantityInUnit: 1,
        unitQuantity: 0,

        isWeightable: 0,

        config: {},
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    getDecimalValue = (value, symbol, toFixed) => {
        // let resVal = value ? Math.round(parseFloat(value) * 10000) / 10000 : 0;
        // if (toFixed) resVal = resVal.toFixed(toFixed)
        let resVal = value ? parseFloat(value).toLocaleString(undefined, {
            minimumFractionDigits: toFixed ?? 2,
            maximumFractionDigits: toFixed ?? 2,
        }) : "0.00"
        return <div style={{ direction: 'ltr', textAlign: 'right', paddingRight: 4 }}>{symbol}{resVal}</div>
    }

    render() {
        console.log("render TableRow")
        const { keyVal, itemCode, itemBarcode, isValid, description, quantity, unitPriceWithTax, discountPercentage, totalSumWithTax, unitPriceNoTax, totalSumNoTax, remarks,
            unitID, quantityInUnit, unitQuantity, unitDescription, isWeightable, costCurrencyId, costCurrencySymbol, costCurrencyRate, costCurrencyValue, serialNumber
        } = this.props;
        const { editedRecord, onLabelPrinterChange } = this.props;
        const { onDelete, onAdd, onCheck, onEdit } = this.props;
        const { isCostNoTax, isCostWithTax, config } = this.props;

        let rowStyle = {}
        switch (isValid) {
            case '0': case 0: rowStyle = { backgroundColor: '#ff3333' }; break;
            case '1': case 1: rowStyle = { backgroundColor: '#ffffff' }; break;
            case '2': case 2: rowStyle = { backgroundColor: '#ffff33' }; break;
            case '3': case 3: rowStyle = { backgroundColor: '#ff33ff' }; break;
            case '4': case 4: rowStyle = { backgroundColor: '#c3c3c3' }; break;
            case '5': case 5: rowStyle = { backgroundColor: '#33ffff' }; break;
        }
        const isCost = config.isUnitCost; //configByDocType(this.props.documentType).isUnitCost;

        //console.log("vvvvvvvvvvv1xx: unitQuantity:", unitQuantity, " quantity: ",  quantity);

        let _Size1 = decodeXml(remarks, 'Size1');
        let _color = decodeXml(remarks, 'Color');
        let isMatrix = !!(_color || _Size1);

        let documentTypeList = Jax.get(this.props.data, "DocTypesCategories", []);
        let dataDoc = documentTypeList.find(f => f.id == this.props.documentType);
        let isSaleDoc = dataDoc && dataDoc.category == 1;

        let barcodeButton = (
            <Tooltip title={this.getText(12524)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"barcode"}
                    onClick={() => {
                        onLabelPrinterChange({ isQtyInput: true, itemCode: itemCode, defaultQty: Math.trunc(quantity) },
                            () => { this.props.uiActions.showModal(TableIDs.labelPrinter) })
                    }} />
            </Tooltip>);

        let deleteButton = (
            <Popconfirm title={this.getText(12520)}
                onConfirm={() => { onDelete(keyVal); }}
                okText={this.getText(12521)} cancelText={this.getText(12522)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
            </Popconfirm>);

        let editButton = (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={(e) => { onEdit(keyVal); }} />)

        let profitButton = isSaleDoc ? (<Tooltip title={this.getText(17173)}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"eye"} onClick={this.props.openModalProfitItem} />
        </Tooltip>) : ""

        //console.log("table row: ", unitID, quantityInUnit, unitQuantity);

        return (
            (window.innerWidth > 600)
                ? <tr
                    style={rowStyle}
                    draggable
                    onDragStart={this.props.onDrag}
                    onDragOver={(e) => { e.preventDefault() }}
                    onDrop={this.props.onDrop}
                >
                    <td>{keyVal}</td>
                    <td colSpan={(isMatrix || serialNumber) ? 1 : 2} >{itemCode}</td>
                    {
                        (isMatrix || serialNumber)
                            ? <React.Fragment>
                                <td >{isMatrix ? itemBarcode : serialNumber}</td>
                            </React.Fragment>
                            : null
                    }
                    <td colSpan={(isMatrix) ? 1 : 3} >{description}</td>
                    {
                        (isMatrix)
                            ? <React.Fragment>
                                <td >{_Size1}</td>
                                <td >{_color}</td>
                            </React.Fragment>
                            : null
                    }
                    {/* <td>{unitID}`${unitDescription}(${quantityInUnit})`{unitDescription}</td> */}
                    <td>{unitDescription}</td>
                    <React.Fragment>
                        <td >{quantityInUnit}</td>
                    </React.Fragment>
                    <td>{this.getDecimalValue(unitQuantity)}</td>
                    <td>{this.getDecimalValue(quantity)}</td>
                    {/* <td>{(!isCostWithTax && isCost) ? unitPriceNoTax : unitPriceWithTax}</td> */}
                    {this.props.documentType == 4 || this.props.documentType == 18 ? <td>{costCurrencyId > 1 ? this.getDecimalValue(costCurrencyValue, costCurrencySymbol) : ""}</td> : ""}
                    <td>{this.getDecimalValue(isCostNoTax ? unitPriceNoTax : unitPriceWithTax, null, 4)}</td>
                    {isCostNoTax && isCostWithTax ? <td>{this.getDecimalValue(unitPriceWithTax, null, 2)}</td> : ""}
                    <td>{this.getDecimalValue(discountPercentage, null, 2)}</td>
                    {/* <td>{(!isCostWithTax && isCost) ? totalSumNoTax : totalSumWithTax}</td> */}
                    {/* <td>{this.getDecimalValue((!isCostWithTax) ? totalSumNoTax : totalSumWithTax,null,2)}</td> */}
                    <td>{this.getDecimalValue(totalSumNoTax, null, 2)}</td>
                    <td>
                        <React.Fragment>
                            {editButton}
                            {deleteButton}
                            {barcodeButton}
                            {profitButton}
                            <Tooltip title={this.getText(16212)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"highlight"}
                                    onClick={this.props.openModalCreateEditItem} />
                            </Tooltip>
                            <Tooltip title={this.getText(19500)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"copy"}
                                    onClick={() => {
                                        let dataSource = [...this.props.data.document2.dataSource];
                                        let curRow = dataSource.find(f => f.keyVal == keyVal)
                                        let newKey = dataSource.length + 1;
                                        dataSource.push({ ...curRow, key: newKey, keyVal: newKey })
                                        this.props.dataActions.setJson("document2", { dataSource })
                                    }} />
                            </Tooltip>

                        </React.Fragment>
                    </td>
                </tr>
                : <React.Fragment>
                    <tr>
                        <td>{this.getText(12513)}</td>
                        <td>{keyVal}</td>
                        <td rowspan="7">
                            <React.Fragment>
                                {editButton}
                                {deleteButton}
                                {barcodeButton}
                                {profitButton}
                            </React.Fragment>
                        </td>
                    </tr>
                    <tr><td>{this.getText(12514)}</td>
                        <td>{itemCode}</td></tr>
                    <tr><td>{this.getText(12515)}</td>
                        <td>{description}</td></tr>
                    <tr><td>{this.getText(12516)}</td>
                        <td>{this.getDecimalValue(quantity)}</td></tr>
                    {this.props.documentType == 4 || this.props.documentType == 18 ?
                        <tr><td>{this.getText(18192)}</td>
                            {/* <td>{(!isCostWithTax && isCost) ? unitPriceNoTax : unitPriceWithTax}</td></tr> */}
                            <td>{this.getDecimalValue(costCurrencyValue, costCurrencySymbol, 2)}</td></tr>
                        : ""}
                    {isCostNoTax ?
                        <tr><td>{this.getText(12517)}</td>
                            {/* <td>{(!isCostWithTax && isCost) ? unitPriceNoTax : unitPriceWithTax}</td></tr> */}
                            <td>{this.getDecimalValue(unitPriceNoTax, null, 2)}</td></tr>
                        : ""}
                    {isCostWithTax ?
                        <tr><td>{this.getText(18627)}</td>
                            {/* <td>{(!isCostWithTax && isCost) ? unitPriceNoTax : unitPriceWithTax}</td></tr> */}
                            <td>{this.getDecimalValue(unitPriceWithTax, null, 2)}</td></tr>
                        : ""}
                    <tr><td>{this.getText(12518)}</td>
                        <td>{this.getDecimalValue(discountPercentage, null, 2)}</td></tr>
                    <tr><td>{this.getText(12519)}</td>
                        {/* <td>{(!isCostWithTax && isCost) ? totalSumNoTax : totalSumWithTax}</td></tr> */}
                        {/* <td>{this.getDecimalValue((!isCostWithTax) ? totalSumNoTax : totalSumWithTax,null,2)}</td></tr> */}
                        <td>{this.getDecimalValue(totalSumNoTax, null, 2)}</td></tr>

                </React.Fragment>
        )
    }

}

class Paging extends PureComponent/*<Props, State>*/ {

    static contextType = LangContext;

    getText = (id) => { return this.context.get(id) || '[' + id + ']' }

    render() {
        const { paging, totalRows, onInputChange } = this.props;
        const { page, numOfPages, rowsPerPage } = paging;
        let styleSpan = { marginRight: 8 };

        const getValidPage = (pageValue) => {
            if (!pageValue) { return 1; }
            if (pageValue > numOfPages) { return numOfPages }
            if (pageValue < 1) { return 1; }
            return pageValue
        }

        return (
            <div className={styles.pagingTableOptimized} style={{ margin: 'auto' }}>
                <span style={styleSpan}><Button disabled={page >= numOfPages} onClick={(e) => { this.props.onChange({ ...paging, page: numOfPages }) }} shape="circle" icon="double-right" /></span>
                <span style={styleSpan}><Button disabled={page >= numOfPages} onClick={(e) => { this.props.onChange({ ...paging, page: parseInt(page, 10) + 1 }) }} shape="circle" icon="right" /></span>
                <span style={styleSpan}><Button disabled={page <= 1} onClick={(e) => { this.props.onChange({ ...paging, page: page - 1 }) }} shape="circle" icon="left" /></span>
                <span style={styleSpan}><Button disabled={page <= 1} onClick={(e) => { this.props.onChange({ ...paging, page: 1 }) }} shape="circle" icon="double-left" /></span>
                <span style={styleSpan}>{this.getText(12539) + ":"}</span>
                <span style={styleSpan}>
                    {/* {page + "/" + numOfPages} */}
                    {numOfPages + "/"}
                    {/* {<InputNumber size="small" precision={0} min={0} max={numOfPages} value={page} defaultValue={1} onChange={(val) => { }}  />} */}
                    {<input style={{ width: 50 }} type={"number"} /*step={1}*/ pattern="\d+"/*precision={0}*/ min={1} max={numOfPages} value={page} defaultValue={1} onChange={(val) => { onInputChange(getValidPage(val.target.value)); }} />}
                </span>
                <span style={styleSpan}>{this.getText(12540) + ":"}</span>
                <span style={styleSpan}>{totalRows}</span>
            </div>
        )
    }
}
