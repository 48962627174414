/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Card, Icon, Row, Col, Popconfirm, InputNumber, Button, Tooltip, Radio, Form, Modal, message, Input, Spin } from 'antd';
import "../../App.css";
import FoodOrderBenefits from "./FoodOrderBenefits";
import GenericSelector from "../../components/GenericSelector";
import { sum } from "lodash";
import moment from "moment";
import cart_but_2 from "../../skins/index/cart_but_2.png"
import list_erase_icon from "../../skins/index/list_erase_icon.png";
const { confirm } = Modal;

type Props = {
    data: Object,
    dataActions: Object,
    isBackOffice: Boolean,
    getText: Function,
    sendAPI: Function,
    changeScreen: Function,
    companyCode: String,
    isShop: Boolean,
    priceNoTax: Boolean,
}

class FoodsFormatCart extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            modalDiscountItem: null,
            discountInlinePermission: null,
            custRemarkEditIndex: -1,
            webViewWaitPermission: false,
            modalCartClear: false,
        }

        this.getText = this.props.getText;

        this.fields_id = "FOOD_FORMAT_ORDER_FIELDS";
        this.levels_id = "FOOD_FORMAT_LEVELS";
        this.cart_id = "FOOD_FORMAT_CART";
        this.mivzaim_cart_id = "FORMAT_MIVZAIM_CART";
        this.coupons_cart_id = "FORMAT_COUPONS_CART";
        this.setting_id = "FORMAT_SITE_SETTINGS";
        this.points_id = "FORMAT_SITE_POINTS";
        this.coupons_id = "FORMAT_COUPONS_CART";
        this.branches_id = "BRANCHES_LIST";
        this.discount_id = "FORMAT_DISCOUNT_ITEMS";


        if (props.data[this.levels_id] === undefined) props.data[this.levels_id] = { data: [], levels: [] };
        if (props.data[this.cart_id] === undefined) props.data[this.cart_id] = { data: [] };
        if (props.data[this.mivzaim_cart_id] === undefined) props.data[this.mivzaim_cart_id] = { data: [] };
        if (props.data[this.coupons_cart_id] === undefined) props.data[this.coupons_cart_id] = { data: [] };
        if (props.data[this.fields_id] === undefined) props.data[this.fields_id] = {};
        if (props.data[this.setting_id] === undefined) props.data[this.setting_id] = {};
        if (props.data[this.points_id] === undefined) props.data[this.points_id] = {};
        if (props.data[this.coupons_id] === undefined) props.data[this.coupons_id] = { dataset: [] };
        if (props.data[this.branches_id] === undefined) props.data[this.branches_id] = { dataset: [] };
        if (props.data[this.discount_id] === undefined) props.data[this.discount_id] = { dataset: [] };

        this.discountOkClicked = false;
        this.discountInlinePermissionPos = null;
    }


    componentDidUpdate(prev) {
        let prevPos = this.discountInlinePermissionPos;
        let curPos = this.props.data[this.fields_id].orderFields ? this.props.data[this.fields_id].orderFields.dPosNumber : null;
        if (prevPos !== curPos && this.props.isFromPos) {
            this.discountInlinePermissionPos = curPos;
            this.props.sendAPI('get_gp_WebViewInlineDiscount', '_pos\r' + curPos, (ob) => { this.setState({ discountInlinePermission: ob.data }) }, this.props.errorCallback)
        }

        if (prev.webViewDiscountInlinePermission !== this.props.webViewDiscountInlinePermission) {
            const { webViewWaitPermission } = this.state;
            const { data, hash } = this.props.webViewDiscountInlinePermission;
            if (data == 1) {
                if (webViewWaitPermission && webViewWaitPermission.onSuccess) webViewWaitPermission.onSuccess()
            } else {
                if (webViewWaitPermission && webViewWaitPermission.onError) webViewWaitPermission.onError()
            }
        }
    }

    getCartId = () => {
        return this.props.dataId ? this.props.dataId : this.cart_id;
    }

    imgUrl = (x) => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        const baseUrl = isDev ? "http://147.235.163.248" : origin
        return baseUrl + "/uploads/" + this.props.companyCode + "/" + x;
    }

    nisFormat = (sum) => {
        return sum && parseFloat(sum) ? parseFloat(sum).toFixed(2) + " ₪" : "0.00 ₪"
    }

    getPriceStyle = (price) => {
        if (typeof price === "string" && price.indexOf("-") > -1) {
            let p = price.split("-");
            return (p[0] !== p[1]) ?
                (<span>{this.nisFormat(p[0])} - {this.nisFormat(p[1])}</span>) :
                this.nisFormat(p[0])
        } else {
            return this.nisFormat(price)
        }
    }

    getSizeColorStyle = (size, color) => {
        let sDiv = size ? (<div>{this.getText(13008)}: {size}</div>) : ""
        let cDiv = color ? (<div>{this.getText(13009)}: {color}</div>) : ""
        return (<div className={'text_item'} style={{ fontSize: 20, lineHeight: 1.2 }}>{sDiv}{cDiv}</div>)
    }

    getPriceItem = (itemObj) => {
        return this.props.priceNoTax ? itemObj.iPriceNoVat : itemObj.iPrice
    }

    getSumCart = () => {
        let cartData = this.props.data[this.getCartId()].data;
        let mivzaimCartData = this.props.data[this.mivzaim_cart_id].data;
        //   let mivzaimCartItems = this.props.data[this.mivzaim_cart_id].itemsTable;
        // let siteSettings = this.props.data[this.setting_id];
        let deliveryCost = this.props.data[this.fields_id].deliveryCost;

        // const {
        //     TotalPointsForUse,
        //     pointsForThisSale,
        // } = this.props.data[this.points_id];

        // let sumPointsCart = pointsForThisSale ? pointsForThisSale.toFixed(2) : '0.00'

        let sumPrices = 0;
        let sumMivzaim = 0;
        let addToPrices = (sp) => { sumPrices = parseFloat(parseFloat(sumPrices) + (sp ? sp : 0)).toFixed(2) }
        cartData.forEach((x) => {
            let priceToSum = parseFloat(this.getPriceItem(x.item)/*x.item.iPrice*/) * parseFloat(x.item.iQty ? x.item.iQty : 1);
            if (x.item.couponData && this.props.isShop) {
                let originPrice = x.item.iFoodDishId ? x.item.iOriginalPrice : this.getPriceItem(x.item)/*x.item.iPrice*/;
                let couponValue = this.getCouponValue(x.item.couponData.nType, originPrice, x.item.couponData.nPrice) * -1;
                addToPrices(couponValue);
            }
            addToPrices(priceToSum);
            if (this.props.isFromPos && x.item.inlineDiscount) {
                addToPrices(x.item.inlineDiscount * x.item.iQty * -1);
            }
        });
        mivzaimCartData.forEach((x) => { sumMivzaim += Math.ceil(x.value * 100) / 100 });

        let couponsData = this.props.data[this.coupons_id].dataset;

        if (couponsData && couponsData.length && !this.props.isShop) {
            couponsData.forEach(x => { addToPrices(parseFloat(x.Value) * -1) })
        }

        if (deliveryCost && deliveryCost.dPrice) addToPrices(parseFloat(deliveryCost.dPrice));

        return parseFloat(sumPrices) - sumMivzaim;
    }

    getTitleCart = (sm) => {
        let cartData = this.props.data[this.getCartId()].data;
        let siteSettings = this.props.data[this.setting_id];
        let isStyle3 = siteSettings.pDesktopCategoriesView == '3';
        let isStyle4 = siteSettings.pDesktopCategoriesView == '4';
        let isStyle5 = siteSettings.pDesktopCategoriesView == '5';
        let isStyle6 = siteSettings.pDesktopCategoriesView == '6';

        const {
            TotalPointsForUse,
            pointsForThisSale,
        } = this.props.data[this.points_id];

        let sumPointsCart = pointsForThisSale ? pointsForThisSale.toFixed(2) : '0.00'

        let topIconStyle = {
            float: 'left',
            background: isStyle3 ? "silver" : isStyle4 ? "#BE1E2D" : isStyle5 ? "orange" : isStyle6 ? "#ddc268" : siteSettings.rPrimaryButtons,
            color: isStyle3 || isStyle4 || isStyle5 ? "white" : isStyle6 ? "black" : siteSettings.rFonts,
            padding: 5,
            marginRight: 5,
            height: 42,
            cursor: 'pointer'
        }

        let qtySum = 0;
        cartData.forEach(x => {
            qtySum += parseFloat(x.item.iQty);
        })

        let titleText = this.getText(13003) + " " +
            // cartData.length + " " +
            qtySum + " " +
            this.getText(13002) + " " +
            this.getText(14154) + " " +
            this.nisFormat(this.getSumCart());

        let pointsText = this.getText(14197) + ": " + TotalPointsForUse + ". " +
            this.getText(14198) + ": " + sumPointsCart;
        console.log('getTitleCart', this.props.isLogin &&
            siteSettings.pIsCustPoints == '1' &&
            TotalPointsForUse &&
            parseFloat(TotalPointsForUse) > 0)

        return (
            <div style={{ fontSize: sm ? 16 : 20, color: '#595a5a' }}>
                <div id="bg_sikum_title" style={{
                    background: isStyle3 ? "silver" : isStyle4 ? "#BE1E2D" : isStyle5 ? "orange" : isStyle6 ? "#ddc268" : siteSettings.rSubTitles,
                    color: isStyle3 ? "black" : isStyle4 ? "white" : isStyle5 ? "#443325" : isStyle6 ? "black" : siteSettings.rFonts,
                    width: '100%'
                }}>
                    <div style={{ textAlign: 'center' }}>
                        {this.props.minimizeCartOn && window.innerWidth >= 700 ?
                            (<span>
                                <div style={topIconStyle} onClick={this.props.cartFullScreenChange}>
                                    <Icon type={this.props.cartFullScreen ? "fullscreen-exit" : "fullscreen"} />
                                </div>
                                <div style={topIconStyle} onClick={this.props.minimizeCartOn}>_</div>
                            </span>)
                            : ''}
                        {titleText}
                    </div>
                </div>
                {this.props.isLogin &&
                    siteSettings.pIsCustPoints == '1' &&
                    TotalPointsForUse &&
                    parseFloat(TotalPointsForUse) > 0 ?
                    (<div className={"text_item"} style={{ textAlign: 'center' }}>
                        {pointsText}
                    </div>)
                    : ""}
                {this.props.typeStyle > 1 ? '' : (
                    <FoodOrderBenefits
                        {...this.props}
                        fontColor={siteSettings.rFonts}
                        backgroundColor={siteSettings.rPrimaryButtons}
                        inCart
                        couponsList={this.props.data[this.cart_id].data.filter(f => f.item.couponData).map(x => x.item.couponData.nCode)}
                    />
                )}
                {this.props.priceNoTax ?
                    (<div style={this.props.typeStyle == '3' ? {
                        fontSize: 13,
                        fontWeight: "bold",
                        margin: 10,
                    } : {}}>
                        {' * ' + this.getText(17536)}
                    </div>)
                    : ""}
            </div>)
    }

    getItemLine = (x, i) => {
        let cartData = [...this.props.data[this.getCartId()].data];
        let siteSettings = this.props.data[this.setting_id];
        let isStyle3 = siteSettings.pDesktopCategoriesView == '3';

        let editItem = () => {
            this.props.editItemCart(x, i)
            this.mivzaimLogic();
        }

        let miniButton = (title, onConfirm, className, tooltip) => {
            return (<Tooltip title={tooltip ? this.props.getText(tooltip) : undefined}>
                <Popconfirm title={this.getText(title)} onConfirm={onConfirm} okText={this.getText(13359)} cancelText={this.getText(13360)}>
                    <div className={className} />
                </Popconfirm>
            </Tooltip>)
        }

        let deleteItemFromCart = () => {
            if (x.couponData) {
                this.unlockNewCoupon(x.couponData);
            }
            cartData.splice(x.index - 1, 1);
            if (this.props.isFromPos && cartData.find(f => f.item.isDiscountItem)) {
                console.log('onDeleteItem', cartData)
                cartData = cartData.filter(f => !f.item.isDiscountItem);
                message.info(this.props.getText(16427))
                // confirm({
                //     // title:,
                //     content:  ,
                //     // iconType: 'exclamation-circle',
                //     // okText: this.getText(12450), // עבור ללשונית מסמך
                //     // cancelText: this.getText(51825), //this.getText(12451), //ביטול
                //     // onOk() {},
                //     // okButtonProps: { style: { display: 'none' } },
                //     // onCancel() { },
                // });
            }
            this.props.dataActions.setJson(this.getCartId(), { data: cartData });
            this.mivzaimLogic();
        }

        // console.log('cart remark item', x.custRemark)

        let deleteItemFromCartMiniButton = !this.props.isFromPos || siteSettings.pWarningDeleteCartInPos == '1' ?
            miniButton(13358, deleteItemFromCart, 'cart_erase_icon', 14444)
            : <Tooltip title={this.props.getText(14444)}><div className={'cart_erase_icon'} onClick={deleteItemFromCart} /></Tooltip>

        let discountInlinePermission = this.state.discountInlinePermission ? parseInt(this.state.discountInlinePermission) : 0;
        let discountInlineMiniButton = discountInlinePermission ?
            (<Tooltip title={this.props.getText(16528)}>
                <div
                    style={{
                        background: '#9ACD32',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        width: 24,
                        height: 24,
                        color: '#ffffff',
                        textAlign: 'center',
                    }}
                    onClick={() => {
                        this.discountOkClicked = false;
                        this.setState({
                            modalDiscountItem: {
                                inlineItem: x,
                                inlineIndex: i,
                                sum: x.inlineDiscount ? x.inlineDiscount : 0,
                                percent: x.inlineDiscount ? (x.inlineDiscount / x.iPrice) * 100 : 0,
                            }
                        })
                    }}>%</div>
            </Tooltip>)
            : ""

        let buttonsNonQty = (<span>
            {discountInlineMiniButton}
            {deleteItemFromCartMiniButton}
            {miniButton(13357, () => {
                let addData = { ...cartData[i], item: { ...cartData[i].item, couponData: null } }
                // console.log('couponData', addData)
                this.props.dataActions.setJson(this.getCartId(), { data: [...cartData, addData] })
                this.mivzaimLogic();
            }, 'cart_plus_icon', 14443)}
            {(x.iFoodDishId && !x.singleItem) || x.iColorID || x.iSize1ID ?
                (<Tooltip title={this.props.getText(14442)}>
                    <div className={'cart_edit_icon_box'} onClick={editItem}>
                        <div className={'cart_edit_icon'} />
                    </div>
                </Tooltip>)
                : ''}
        </span>);

        let stepQty = parseFloat(x.stepQty) ? parseFloat(x.stepQty) : 1;
        let minQty = parseFloat(x.minQty) ? parseFloat(x.minQty) : 1;
        let maxQty = parseFloat(x.maxQty) ? parseFloat(x.maxQty) : 999999;
        let iQty = parseFloat(x.iQty) ? parseFloat(x.iQty) : 1;

        let precision = stepQty.toString().split('.')[1] ? stepQty.toString().split('.')[1].length : 0;

        let inlineDiscount = this.props.isFromPos && x.inlineDiscount ? x.inlineDiscount : 0

        const onChangeQty = (e) => {
            if (parseFloat(e) <= maxQty && parseFloat(e) >= minQty) {
                let newData = [...this.props.data[this.cart_id].data];
                newData[i] = { ...newData[i], item: { ...newData[i].item, iQty: parseFloat(e).toFixed(precision) } }
                this.props.dataActions.setJson(this.getCartId(), { data: newData });
                this.mivzaimLogic();
            }
        }
        // console.log('inlineDiscount', x.inlineDiscount)

        let qtyInput = (<span>
            {discountInlineMiniButton}
            {deleteItemFromCartMiniButton}
            <InputNumber
                style={{ width: '60%', fontSize: 20, float: 'left' }}
                step={stepQty}
                min={minQty}
                max={maxQty}
                precision={precision}
                value={iQty}
                onChange={onChangeQty}
            />
        </span>);


        if (isStyle3) {
            let plusMinusBtnStyle = {
                width: 20,
                height: 20,
                padding: 5,
                color: "white",
                borderRadius: "50%",
                cursor: "pointer",
                fontSize: 30,
                lineHeight: 0.2,
                display: "inline-block",
                verticalAlign: "text-top",
            }
            let qtyStyle = {
                display: "inline-block",
                verticalAlign: "text-top",
                fontSize: 40,
                lineHeight: 0.3,
            }
            qtyInput = (<Row style={{ textAlign: "center" }}>
                <Col span={4} style={{ ...plusMinusBtnStyle, background: "#88c23f" }} onClick={() => { onChangeQty(iQty + 1) }}>+</Col>
                <Col span={10} style={qtyStyle}>{iQty}</Col>
                <Col span={4} style={{ ...plusMinusBtnStyle, background: "red" }} onClick={() => { onChangeQty(iQty - 1) }}>-</Col>
                <Col span={6} >{deleteItemFromCartMiniButton}</Col>
            </Row>);
        }

        let custRemarkBtn = (<Tooltip title={this.props.getText(16588)}>
            <span
                style={{ background: "#ccccff", float: 'left', cursor: "pointer", width: 15, height: 15, fontSize: 15, borderRadius: '50%', }}
                onClick={() => {
                    this.setState({ custRemarkEditIndex: this.state.custRemarkEditIndex == i ? -1 : i })
                }}>
                <Icon type={this.state.custRemarkEditIndex != i ? "more" : "check"} />
            </span>
        </Tooltip>)

        return (<Row className={"text_item"} style={{ fontSize: 20, fontWeight: 700 }}>
            <Col span={isStyle3 ? 10 : 8}>{x.iName}</Col>
            <Col span={isStyle3 ? 6 : 8} style={{ textAlign: "center" }}>
                {this.nisFormat(this.getPriceItem(x)/*x.iPrice*/)}
                {inlineDiscount && x.iPrice ? (<div>{this.props.getText(16529) + ': ' + this.nisFormat(x.iPrice - inlineDiscount)}</div>) : ""}
            </Col>
            <Col span={8}>
                {this.props.isShop ? (x.iFoodDishId || !parseFloat(x.stepQty)) ? buttonsNonQty : qtyInput : x.iQty ? parseFloat(x.iQty) : ''}
                {/* {this.props.copyItemButton ? this.props.copyItemButton(x, i) : ""} */}
            </Col>
            {this.props.isShop && !isStyle3 ?
                x.custRemark || this.state.custRemarkEditIndex == i ?
                    (<Col span={24} style={{ background: "#ddddff", fontSize: 15, borderRadius: 5, padding: 5 }}>
                        {this.state.custRemarkEditIndex != i ?
                            (<span style={{ height: 25, overflow: 'hidden' }}>{x.custRemark}</span>)
                            : (<Input.TextArea
                                autoFocus
                                value={x.custRemark}
                                onChange={e => {
                                    let data = [...this.props.data[this.cart_id].data];
                                    data[i] = { ...data[i], item: { ...data[i].item, custRemark: e.target.value } }
                                    this.props.setJson(this.cart_id, { data })
                                }}
                            >{x.custRemark}</Input.TextArea>)}
                        {custRemarkBtn}
                    </Col>)
                    : (<Col span={24}>{custRemarkBtn}</Col>)
                : ""}
        </Row>)
    }

    mivzaimLogic = () => {
        setTimeout(() => {
            this.props.mivzaimLogic(this.getCartId());
        }, 500);
    }

    getExtraListType = (list, label, oPizzaPiecesNum) => {
        return list[0] ? (<div>
            <u>{this.getText(label)}:</u>
            {list.map((et, ii) => {
                let eTypeText = this.props.getPiecesText(et.eLocals, oPizzaPiecesNum);
                return (<span>
                    {' ' + et.eItemName}
                    {eTypeText ? '(' + eTypeText + ')' : ''}
                    {(ii === list.length - 1) ? "." : ","}
                </span>)
            })}
        </div>) : ""
    }

    mivzaLineStyle = (name, value, typeText, deleteBtn, onClick) => {
        let valueFormat = value ? this.nisFormat(value * -1) : null;
        let notValueText = value ? '' : this.getText(15697)

        let siteSettings = this.props.data[this.setting_id];

        let deleteItemFromCart = () => {
            if (deleteBtn) {
                let cartId = this.getCartId()
                let cartData = this.props.data[cartId].data;
                cartData.splice(deleteBtn - 1, 1);
                this.props.dataActions.setJson(cartId, { data: cartData });
            }
        }

        let deleteItemFromCartMiniButton = siteSettings.pWarningDeleteCartInPos == '1' ?
            <Tooltip title={this.props.getText(14444)}>
                <Popconfirm title={this.getText(13358)} onConfirm={deleteItemFromCart} okText={this.getText(13359)} cancelText={this.getText(13360)}>
                    <div className={'cart_erase_icon'} />
                </Popconfirm>
            </Tooltip>
            : <Tooltip title={this.props.getText(14444)}><div className={'cart_erase_icon'} onClick={deleteItemFromCart} /></Tooltip>


        return (<Card style={{ marginTop: 10, lineHeight: 1.2, background: "pink" }}>
            <Row className={"text_item"} style={{ fontSize: 20, fontWeight: 700 }}>
                <Col span={12} onClick={onClick}>
                    {name}
                    <div style={{ fontSize: 14 }}>{notValueText}</div>
                </Col>
                <Col span={deleteBtn ? 6 : 8} onClick={onClick}>{valueFormat}</Col>
                <Col span={4} onClick={onClick}>
                    <div style={{ color: '#FFFF00', textAlign: 'center' }} >
                        <Icon type="star" />
                        <p style={{ fontSize: 15 }}>{this.props.getText(typeText)}</p>
                    </div>
                </Col>
                {deleteBtn ? <Col span={2}>{deleteItemFromCartMiniButton}</Col> : ""}
            </Row>
        </Card>)
    }

    getCouponValue = (type, originPrice, couponPrice) => {
        switch (type) {
            case '1': return Math.ceil((parseFloat(originPrice) - parseFloat(couponPrice)) * 100) / 100;
            case '2': return Math.ceil(parseFloat(originPrice) * parseFloat(couponPrice)) / 100;
            default: return 0;
        }
    }

    unlockNewCoupon = (cData) => {
        if (!cData.nIsMultipass)
            this.props.sendAPI('unlock_new_coupon', 'nCode\fnTransactionId\r' + cData.nCode + '\f' + cData.mHash, () => { }, this.props.errorCallback)
    }

    getPopconfirmCartClear = (content) => {
        const onCancel = () => {
            this.setState({ modalCartClear: false })
        }

        const onClear = () => {
            this.props.data[this.getCartId()].data.filter(f => f.item.couponData).forEach(x => {
                this.unlockNewCoupon(x.item.couponData);
            })
            this.props.dataActions.setJson(this.getCartId(), { data: [] });
            this.props.dataActions.setJson(this.mivzaim_cart_id, { data: [] });
            if (this.props.ownerUpdate) this.props.ownerUpdate(false);
            this.mivzaimLogic();
            onCancel()
        }

        if (this.props.data[this.setting_id].pDesktopCategoriesView == '4') {

            let mStyle = {
                color: "white",
                background: "#bb0000",
                textAlign: "center",
                height: 50,
                fontSize: 20,
                padding: 10
            }

            let bStyle = {
                ...mStyle,
                height: 40,
                width: 150,
                fontSize: 18,
                padding: 5,
                margin: 20,
                marginTop: 0,
                marginBottom: 40,
                cursor: "pointer",
                display: "inline-block"
            }

            return (<span>
                <span style={{ cursor: "pointer" }} onClick={() => { this.setState({ modalCartClear: true }) }}>{content}</span>
                <Modal visible={this.state.modalCartClear} onCancel={onCancel} title={(<div style={mStyle}>{this.getText(18653)}</div>)} className={"padding_0"} width={400} footer={false}>
                    <div style={{ textAlign: "center", padding: 10 }}>
                        <p style={{ fontSize: 20, padding: 20, paddingBottom: 10, color: "black" }}>{this.getText(18654)}</p>
                        <div style={bStyle} onClick={onClear}>{this.getText(18655)}</div>
                        <div style={bStyle} onClick={onCancel}>{this.getText(18656)}</div>
                    </div>
                </Modal>
            </span>)
        } else {
            return (<Popconfirm title={this.getText(13378)} onConfirm={onClear} okText={this.getText(13359)} cancelText={this.getText(13360)}> {content}</Popconfirm >)
        }
    }

    discountButton = () => {
        let issetDiscount = this.props.data[this.cart_id].data.filter(f => f.item.isDiscountItem).length;
        let discountData = this.props.data[this.discount_id].dataset;
        let siteSettings = this.props.data[this.setting_id];
        if (this.props.isFromPos && this.props.isShop && discountData.length && parseFloat(this.getSumCart()) && !issetDiscount) {
            let buttonStyle = { background: siteSettings.rPrimaryButtons, color: siteSettings.rFonts, fontSize: 20 }

            return (<div style={buttonStyle} className="btn_footer_cart_2" onClick={() => {
                this.discountOkClicked = false;
                this.setState({
                    modalDiscountItem: {
                        itemCode: siteSettings.pDiscountItem,
                        itemName: siteSettings.pDiscountItem ? discountData[0].name : "",
                        sum: 0,
                        percent: 0,
                    }
                })
            }}>
                <span className={this.props.getFontStyle2("text_item")} style={{ color: siteSettings.rFonts }}>{this.getText(16214)}</span>
            </div>)
        } else {
            return '';
        }
    }

    modalDiscountToPos = () => {
        let discountData = this.props.data[this.discount_id].dataset;
        let siteSettings = this.props.data[this.setting_id];
        const { modalDiscountItem } = this.state;
        let modalData = modalDiscountItem ? modalDiscountItem : {}

        let inlineItem = modalData.inlineItem;
        let inlineIndex = modalData.inlineIndex;

        let buttonsStyle = {
            margin: 10,
            width: 140,
            height: 50,
            background: siteSettings.rPrimaryButtons,
        }

        let totalCart = parseFloat(this.getSumCart());
        let discountInlinePermission = this.state.discountInlinePermission ? parseInt(this.state.discountInlinePermission) : 0;
        let sumCart = inlineItem ? parseFloat(inlineItem.iPrice) : totalCart
        let maxPercent = inlineItem ? discountInlinePermission : 100;
        // if (totalCart < sumCart) maxPercent = maxPercent * (totalCart / sumCart)
        let prevDiscount = inlineItem ? this.props.data[this.cart_id].data[inlineIndex].item.inlineDiscount : 0;
        if (!prevDiscount) prevDiscount = 0;
        let itemChecked = modalData.itemCode;
        let disabled = (!itemChecked && !inlineItem) || modalData.percent < 0;


        let footer = [
            <Button
                style={{ ...buttonsStyle, background: siteSettings.rSecondaryButtons }}
                onClick={() => {
                    this.setState({ modalDiscountItem: null, webViewWaitPermission: null })
                }}>
                <span className={this.props.getFontStyle2("text_item")} style={{ color: siteSettings.rFonts }}>{this.getText(16223)}</span>
            </Button>,
            <Button
                style={buttonsStyle}
                disabled={disabled}
                onClick={() => {
                    let onSuccess = inlineItem ? () => {
                        let newData = this.props.data[this.getCartId()].data.map((x, i) => {
                            return (i === inlineIndex) ? { ...x, item: { ...x.item, inlineDiscount: modalData.sum } } : x
                        });
                        this.props.setJson(this.cart_id, { data: newData })
                        this.mivzaimLogic()
                        this.setState({ modalDiscountItem: null, webViewWaitPermission: null })
                    } : () => {
                        this.props.addToCart({
                            iCode: modalData.itemCode,
                            iName: modalData.itemName,
                            iPrice: modalData.sum,
                            iQty: -1,
                            isDiscountItem: true,
                        })
                        this.setState({ modalDiscountItem: null, webViewWaitPermission: null })
                    }

                    if ((totalCart + prevDiscount - modalData.sum) < 0) {
                        this.setState({ modalDiscountItem: { ...modalDiscountItem, sum: 0, percent: 0, error: this.getText(16812) } })
                    } else if (window.Android && window.Android.webViewGetPermission) {
                        this.setState({
                            webViewWaitPermission: {
                                onSuccess,
                                onError: () => { this.setState({ webViewWaitPermission: null }) }
                            }
                        }, () => {
                            let permissionId = inlineItem ? 19 : 20;
                            let pItemCode = inlineItem ? inlineItem.iCode : "";
                            let pItemName = inlineItem ? inlineItem.iName : "";
                            window.Android.webViewGetPermission(permissionId, modalData.percent, sumCart, pItemCode, pItemName);
                        })
                    } else if (modalData.percent <= maxPercent && modalData.percent > 0) {
                        if (!this.discountOkClicked && modalData.sum >= 0) {
                            this.discountOkClicked = true;
                            onSuccess()
                        }

                    } else {
                        this.setState({
                            modalDiscountItem: { ...modalDiscountItem, sum: 0, percent: 0, error: this.getText(16710) + maxPercent + "%" },
                            webViewWaitPermission: null
                        })
                    }

                }}>
                <span className={this.props.getFontStyle2("text_item")} style={{ color: siteSettings.rFonts }}>{this.getText(16224)}</span>
            </Button>
        ]

        let step1 = (<span>
            <div className={this.props.getFontStyle2("text_item")} /*style={{ color: siteSettings.rFonts }}*/ >{this.getText(16216)}: </div>
            {discountData.map(x => {
                return (<Button style={buttonsStyle} onClick={() => {
                    this.setState({ modalDiscountItem: { ...modalDiscountItem, itemCode: x.code, itemName: x.name } })
                }}><span className={this.props.getFontStyle2("text_item")} style={{ color: siteSettings.rFonts }}>{x.name}</span></Button>)
            })}
        </span>)


        let step2 = [
            <Row>
                <Col span={10}>
                    <span className={this.props.getFontStyle2("text_item")} /*style={{ color: siteSettings.rFonts }}*/ >{this.getText(16219)}: </span>
                </Col>
                <Col span={10}>
                    <InputNumber onFocus={(e) => { e.target.select() }} value={modalData.percent} onChange={(e) => {
                        this.setState({ modalDiscountItem: { ...modalDiscountItem, sum: Math.round(sumCart * e) / 100, percent: e, error: "" } })
                    }} />
                </Col>
            </Row>,
            <Row>
                <Col span={10}>
                    <span className={this.props.getFontStyle2("text_item")} /*style={{ color: siteSettings.rFonts }}*/ >{this.getText(16220)}: </span>
                </Col>
                <Col span={10}>
                    <InputNumber onFocus={(e) => { e.target.select() }} value={modalData.sum} onChange={(e) => {
                        this.setState({ modalDiscountItem: { ...modalDiscountItem, sum: e, percent: Math.round((e / sumCart) * 10000) / 100, error: "" } })
                    }} />
                </Col>
            </Row>,
            <Row>
                <Col span={10}>
                    <span className={this.props.getFontStyle2("text_item")} /*style={{ color: siteSettings.rFonts }}*/ >{this.getText(16709)}: </span>
                </Col>
                <Col span={10}>
                    <InputNumber onFocus={(e) => { e.target.select() }} value={sumCart - modalData.sum} onChange={(e) => {
                        this.setState({ modalDiscountItem: { ...modalDiscountItem, sum: sumCart - e, percent: Math.round(((sumCart - e) / sumCart) * 10000) / 100, error: "" } })
                    }} />
                </Col>
            </Row>
        ];

        let modalTitle = inlineItem ? this.getText(16528) + ' ' + inlineItem.iName : this.getText(16215)


        return (<Modal
            zIndex={10000}
            title={this.props.getClassNameStyle("title_block", {}, modalTitle)}
            visible={modalDiscountItem} onCancel={() => this.setState({ modalDiscountItem: null })} destroyOnClose={true} width={500}
            footer={footer}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            {inlineItem || itemChecked ? step2 : step1}
            <span style={{ color: 'red' }}>{modalData.error}</span>
        </Modal>)
    }

    endOrder = () => {
        console.log('test webview lazi load 1', moment())
        this.props.changeScreen(2);
    }


    render() {

        console.log('cartpage', this.props)
        let gt = this.props.getText;
        let cartData = this.props.data[this.getCartId()].data;
        let siteSettings = this.props.data[this.setting_id];
        let orderData = this.props.data[this.fields_id];
        let isStyle2 = siteSettings.pDesktopCategoriesView == '2';
        let isStyle3 = siteSettings.pDesktopCategoriesView == '3';
        let isStyle4 = siteSettings.pDesktopCategoriesView == '4';
        let isStyle5 = siteSettings.pDesktopCategoriesView == '5';
        let isStyle6 = siteSettings.pDesktopCategoriesView == '6';
        let branchData = orderData.orderFields && orderData.needAddress === '0' ?
            this.props.data[this.branches_id].dataset.find(f => f.code === orderData.orderFields.dBranch)
            : null

        let buttonStyle = { background: siteSettings.rPrimaryButtons, color: siteSettings.rFonts }
        let buttonStyle3 = {
            color: "white", display: "inline-block", verticalAlign: "text-top", width: "45%", margin: "2%", fontSize: 18, padding: 2,
            fontFamily: "FbTipograf", cursor: "pointer"
        }


        let headerStyle2 = (isStyle2 || isStyle3 || isStyle4 || isStyle5) && branchData ? (
            <div id="header_cart_2" >
                {this.props.isShop ?
                    (<div
                        id="btn_header_cart_2"
                        style={isStyle4 || isStyle5 || isStyle6 ? { background: isStyle4 ? "#BE1E2D" : isStyle5 ? "orange" : isStyle6 ? "#ddc268" : "", fontSize: 20, width: "95%" } : {}}
                        onClick={() => { this.props.changeModal(orderData.orderType) }}
                    >{gt(16033)}</div>)
                    : ""}
                <div>{gt(16034)}: <b>{branchData.name}</b></div>
                {branchData.prepareTime ?
                    <div>{gt(16035)}: <b>{gt(16036)}-{branchData.prepareTime} {gt(16037)}</b></div>
                    : ""}
                <hr />
            </div>
        ) : "";

        let sumCart = this.getSumCart();

        let footerStyle = (isStyle2 || isStyle3 || isStyle4 || isStyle5) ? (
            <div id="footer_cart_2" style={{ padding: isStyle3 || isStyle4 || isStyle5 ? 0 : "auto" }}>
                {isStyle2 ? <hr /> : ""}
                <div>{gt(16038)}</div>
                <div style={{ fontSize: 72, marginTop: -22 }}><b>{sumCart ? sumCart.toFixed(2) : '0.00'} ₪</b></div>
                {this.props.isShop ?
                    isStyle2 ? [
                        <div style={buttonStyle} className="btn_footer_cart_2" onClick={this.endOrder}>
                            {this.getText(13007)}
                        </div>,
                        this.getPopconfirmCartClear(
                            <div className="btn_footer_cart_2" style={{ ...buttonStyle, background: siteSettings.rSecondaryButtons }} >
                                {this.getText(13005)}
                            </div>)
                    ]
                        : [
                            <div style={{ ...buttonStyle3, background: '#88c23f' }} onClick={this.endOrder}>
                                <img src={cart_but_2} style={{ width: 24, float: "right", margin: 2, marginRight: 7 }} />
                                {this.getText(13007)}
                            </div>,
                            this.getPopconfirmCartClear(<div style={{ ...buttonStyle3, background: 'red' }} >
                                <img src={list_erase_icon} style={{ width: 24, float: "right", margin: 2, marginRight: 7 }} />
                                {this.getText(13005)}
                            </div>)
                        ]
                    : ""}
            </div>
        ) : "";


        let titleStyle = this.getTitleCart(false)

        let contentStyle = {
            backgroundColor: isStyle3 ? "white" : '#f1f1f2',
            minHeight: window.innerWidth <= 700 ? window.innerHeight * 0.6 : 100
        }

        if (window.innerWidth > 700) {
            contentStyle = {
                ...contentStyle,
                height: window.innerHeight - (this.props.isFromPos ? 0 : 70) - (isStyle2 || isStyle3 || isStyle4 || isStyle5 ? 200 : isStyle6 ? 304 : 350),
                overflowY: 'scroll',
            }
        }

        if (this.props.cartFullScreen) {
            contentStyle = {
                ...contentStyle,
                height: isStyle4 || isStyle5 ? 'auto' : window.innerHeight - 200,
                columnWidth: 'auto',
                columnCount: '3'
            }
        }

        let dataSource = cartData.map((x, i) => { return { ...x.item, index: i + 1 } });

        let sortExtraItems = (e, oPizzaPiecesNum) => {
            return (<div>
                {this.getExtraListType(e.filter(f => f.eType == 4), 12993, oPizzaPiecesNum)}
                {this.getExtraListType(e.filter(f => f.eType == 2), 12994, oPizzaPiecesNum)}
                {this.getExtraListType(e.filter(f => f.eType == 3), 12995, oPizzaPiecesNum)}
            </div>)
        }

        let cartActions = isStyle2 || isStyle3 || isStyle4 || isStyle5 ? false : [
            this.getPopconfirmCartClear(
                <Button id="erase_but" style={{ ...buttonStyle, background: isStyle6 ? "#ddc268" : siteSettings.rSecondaryButtons }} >
                    <div id="recycle_bin_but"></div>
                    {this.getText(13005)}
                </Button>),
            <Button style={buttonStyle} id="buy_but" onClick={this.endOrder}>
                <div className={isStyle6 ? "cart_style_6" : "cart_but"}></div>
                {isStyle6 ? <b>{this.getText(13007)}</b> : this.getText(13007)}
            </Button>,
        ];

        let deliveryCost = this.props.data[this.fields_id].deliveryCost;

        let cartItemStyle = {
            marginTop: 10,
            lineHeight: 1.2,
            border: isStyle3 ? "0px" : "auto",
            borderBottom: "1px solid #e8e8e8",
        }

        let couponsData = this.props.data[this.coupons_id].dataset;

        let cartStyle = this.props.cartFullScreen ? { top: 20, width: '96%', zIndex: 2000 }
            : isStyle2 ? { top: this.props.isFromPos ? 130 : 200 }
                : isStyle3 ? { top: 150, left: 0, width: window.innerWidth < 700 ? '100%' : window.innerWidth * 0.22, background: "white" }
                    : isStyle4 || isStyle5 ? { width: window.innerWidth < 700 ? '100%' : window.innerWidth * 0.22, background: "white", top: 150 }
                        : isStyle6 ? { left: 5, top: this.props.isShop ? 120 : 0}
                            : {}

        return (<Card
            title={titleStyle}
            id={this.props.isShop ? this.props.isFromPos ? "cart_shop_from_pos" : "cart_shop" : "cart_shop_end"}
            style={cartStyle}
            actions={this.props.isShop && window.innerWidth > 700 ? cartActions : false}
            headStyle={{ border: isStyle3 ? "0px" : "auto" }}
        >
            <div style={contentStyle} id={"contentCart"} >
                {headerStyle2}
                <div style={{ minHeight: 150 }}>
                    {dataSource.filter(f => !f.isDiscountItem).map((x, i) => {
                        let iLevels = cartData[x.index - 1].levels;

                        let resItem = (<Card style={cartItemStyle}>
                            {this.getItemLine(x, i)}
                            {iLevels ? (<div>
                                {iLevels.map((l) => {
                                    return l.ownerData.length ?
                                        (<div className={'text_item'} style={{ marginTop: 5, padding: 5 }}>
                                            {iLevels.length == 1 ? "" : <div style={{ fontSize: 17, fontWeight: 600 }}>{l.name}</div>}
                                            <div style={{ fontSize: 15 }}>
                                                {l.ownerData.map((o) => {
                                                    return (<div>
                                                        <div>{o.oItemName}</div>
                                                        <div>{o.oExtraItems ? sortExtraItems(o.oExtraItems, o.oPizzaPiecesNum) : ""}</div>
                                                    </div>)
                                                })}
                                            </div>
                                        </div>)
                                        : ''
                                })}
                            </div>)
                                : x.iSizeDesc || x.iColorDesc ? this.getSizeColorStyle(x.iSizeDesc, x.iColorDesc) : ""}
                        </Card>);

                        let mivzaFind = this.props.data[this.mivzaim_cart_id].data.filter(f => f.lastItem === i);

                        let resMivza = mivzaFind ? mivzaFind.map((mf) => this.mivzaLineStyle(mf.data.mDesc, Math.ceil(mf.value * 100) / 100, 14110)) : ""

                        let resCoupon = '';
                        if (x.couponData && this.props.isShop) {
                            let originPrice = x.iFoodDishId ? x.iOriginalPrice : this.getPriceItem(x)/*x.iPrice*/;

                            let couponValue = this.getCouponValue(x.couponData.nType, originPrice, x.couponData.nPrice);
                            //  (x.couponData.nType == '1') ?
                            //     Math.ceil((parseFloat(x.iPrice) - parseFloat(x.couponData.nPrice)) * 100) / 100 :
                            //     Math.ceil(parseFloat(x.iPrice) * parseFloat(x.couponData.nPrice)) / 100;

                            resCoupon = this.mivzaLineStyle(x.couponData.nName, couponValue, 15692)
                        }

                        return (<span>{resItem}{resMivza}{resCoupon}</span>);
                    })}
                    {(couponsData && couponsData.length && !this.props.isShop) ?
                        couponsData.map(x => this.mivzaLineStyle(x.Description, x.Value, 15692))
                        : ""}
                    {dataSource.filter(f => f.isDiscountItem).map(x => {
                        return (<div
                            style={{ cursor: 'pointer' }}>
                            {this.mivzaLineStyle(x.iName, x.iPrice, 16214, x.index, () => {
                                let sumCart = parseFloat(this.getSumCart());
                                this.setState({
                                    modalDiscountItem: {
                                        itemCode: x.iCode,
                                        itemName: x.iName,
                                        sum: x.iPrice,
                                        percent: Math.round(x.iPrice / (sumCart + x.iPrice) * 10000) / 100,
                                    }
                                }, () => {
                                    let data = this.props.data[this.cart_id].data.filter(f => !f.item.isDiscountItem);
                                    this.props.setJson(this.cart_id, { data })
                                    this.discountOkClicked = false;
                                })
                            })}</div>)
                    })}
                    {deliveryCost ?
                        (<Card style={cartItemStyle}>
                            <Row className={"text_item"} style={{ fontSize: 20, fontWeight: 700 }}>
                                <Col span={8}>{deliveryCost.dItemName}</Col>
                                <Col span={16}>{this.nisFormat(deliveryCost.dPrice)}</Col>
                            </Row>
                        </Card>)
                        : ""}
                </div>
                {this.discountButton()}
                {footerStyle}
            </div>
            {this.props.isShop && window.innerWidth <= 700 && cartActions ?
                (<Row style={{ position: 'fixed', bottom: 20, width: '70%' }}>
                    {cartActions.map((x) => (<Col span={12}>{x}</Col>))}
                </Row>)
                : ''}
            {this.props.isFromPos && this.props.isShop ? this.modalDiscountToPos() : ""}
        </Card>)
    }
}

export default FoodsFormatCart;