
import React from "react";
import { Row, Col, Icon, Button, InputNumber } from 'antd';
import GenericModal from "./GenericModal";
import LangContext from "../contextProvider/LangContext";


class ModalBonusCulc extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            modalBonusCulc: null,
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    getModalBonusCulcField = (label, field) => {
        let editedRecord = this.state.modalBonusCulc ? this.state.modalBonusCulc : {}
        let value = editedRecord[field] ? Math.round(editedRecord[field] * 100) / 100 : 0;
        let isPercent = field === 'mSupDscnt1' || field === 'mSupDscnt2' || field === 'mSupDscnt3';
        let issetDscnt = parseFloat(editedRecord.mSupDscnt1) || parseFloat(editedRecord.mSupDscnt2) || parseFloat(editedRecord.mSupDscnt3)
        let isSum = (field === 'mCostIncludeDscnt' && issetDscnt) || field === 'mCostIncludeBonus';
        let disabled = field === 'mSupDscnt3' && this.props.disableDscnt3

        return (<Row>
            <Col span={16} style={{ textAlign: 'left', padding: 5 }}>
                {isSum ? (<b>{this.getText(label)}</b>) : this.getText(label)}:
            </Col>
            <Col span={8} style={{ textAlign: 'right', padding: 5 }}>
                {isSum ? <b>{value}</b> : (
                    <InputNumber
                        min={0} step={1} precision={2}
                        max={isPercent ? 100 : undefined}
                        disabled={disabled}
                        value={value}
                        onChange={e => {
                            let newRecord = { ...editedRecord }
                            let fieldsToFloat = ["mBaseCost", 'mSupDscnt1', 'mSupDscnt2', 'mSupDscnt3', 'mCostIncludeDscnt', 'mBonusQty', 'mQtyForBonusQty', 'mCostIncludeBonus']
                            fieldsToFloat.forEach(x => {
                                newRecord[x] = parseFloat(editedRecord[x]) > 0 ? parseFloat(editedRecord[x]) : 0
                            });
                            newRecord[field] = e;
                            if (field === "mCostIncludeDscnt") newRecord['mBaseCost'] = newRecord['mCostIncludeDscnt'];
                            else newRecord['mCostIncludeDscnt'] = newRecord['mBaseCost'];
                            newRecord['mCostIncludeDscnt'] = newRecord['mCostIncludeDscnt'] * (1 - (newRecord['mSupDscnt1'] / 100));
                            newRecord['mCostIncludeDscnt'] = newRecord['mCostIncludeDscnt'] * (1 - (newRecord['mSupDscnt2'] / 100));
                            newRecord['mCostIncludeDscnt'] = newRecord['mCostIncludeDscnt'] * (1 - (newRecord['mSupDscnt3'] / 100));
                            newRecord['mCostIncludeBonus'] = newRecord['mCostIncludeDscnt'];
                            if (newRecord['mBonusQty'] > 0 && newRecord['mQtyForBonusQty'] > 0) {
                                let culcBonusItems = newRecord['mQtyForBonusQty'] / (newRecord['mBonusQty'] + newRecord['mQtyForBonusQty'])
                                newRecord['mCostIncludeBonus'] = newRecord['mCostIncludeBonus'] * culcBonusItems;
                            }
                            newRecord['mCostIncludeBonus'] = Math.round(newRecord['mCostIncludeBonus'] * 100) / 100
                            newRecord['mItemCostNoVat'] = newRecord['mCostIncludeBonus'];
                            this.setState({ modalBonusCulc: newRecord })
                        }} />
                )}
            </Col>
        </Row>)
    }

    openModal = () => { if (this.props.modalData) this.setState({ modalBonusCulc: this.props.modalData }) }

    closeModal = () => { this.setState({ modalBonusCulc: null }, this.props.onClose) }

    componentDidUpdate(p){
        if(this.props.openModalOnBlurQty && !p.openModalOnBlurQty) this.openModal()
    }

    render() {
        const { modalData, handleOk, style } = this.props;
        const { mCostIncludeDscnt, mBonusQty } = modalData ? modalData : {};

        let color = 'black';
        if (!modalData) color = '#aaaaaa';
        if (mCostIncludeDscnt) color = '#40a9ff';
        if (mBonusQty) color = '#9ACD32';

        return (<span>
            <Icon
                type="setting"
                onClick={this.openModal}
                style={{ color, ...style }}
            />
            <GenericModal
                visible={this.state.modalBonusCulc !== null}
                onCancel={this.closeModal}
                width={400}
                title={this.getText(16433)}
                footer={[
                    <Button onClick={this.closeModal}>{this.getText(16444)}</Button>,
                    <Button type={'primary'} onClick={() => {
                        const newRecord = { ...this.state.modalBonusCulc }
                        handleOk(newRecord)
                        this.closeModal()
                    }}>{this.getText(16445)}</Button>,
                ]}>
                {this.getModalBonusCulcField(16434, 'mBaseCost')}
                {this.getModalBonusCulcField(16435, 'mSupDscnt1')}
                {this.getModalBonusCulcField(16436, 'mSupDscnt2')}
                {this.getModalBonusCulcField(16437, 'mSupDscnt3')}
                {this.getModalBonusCulcField(16438, 'mCostIncludeDscnt')}
                {this.getModalBonusCulcField(16439, 'mBonusQty')}
                {this.getModalBonusCulcField(16440, 'mQtyForBonusQty')}
                {this.getModalBonusCulcField(16441, 'mCostIncludeBonus')}
            </GenericModal>
        </span>);

    }

}
export default ModalBonusCulc;
