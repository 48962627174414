/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Switch, Card, Form, Select, Checkbox, InputNumber, Input, Radio } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import CustomerPicker from "../../components/CustomerPicker";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import PageToolbar from "../../components/PageToolbar";
import SelectorItemsMulti from "../../components/SelectorItemsMulti";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import TagRelationRadio from "../../components/TagRelationRadio";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import CheckboxIncludeSendingDocs from "../../components/CheckboxIncludeSendingDocs";
import GenericSelectorItemTagsWithRelation from "../../components/GenericSelectorItemTagsWithRelation";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import GenericSelectorCustomers from "../../components/GenericSelectorCustomers";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class SalesDetailedReport extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,

            ApplyRangeLowerCustomerName: '',
            ApplyRangeUpperCustomerName: '',
            type: "pdf",
            RequestedReport: "Tags",
            itemNameLower: "",
            itemNameUpper: "",

            SalesDetailed: {
                ApplyRangeApplyItemCode: 0,
                ApplyRangeLowerItemCode: '',
                ApplyRangeUpperItemCode: '',

                ApplyRangeApplyItemName: 0,
                ApplyRangeLowerItemName: '',
                ApplyRangeUpperItemName: '',

                ApplyRangeApplySupplierCode: 0,
                ApplyRangeLowerSupplierCode: 0,
                ApplyRangeUpperSupplierCode: 0,

                ApplyRangeApplySupplierName: 0,
                ApplyRangeLowerSupplierName: '',
                ApplyRangeUpperSupplierName: '',

                ApplyRangeApplyCustomerCode: 0,
                ApplyRangeLowerCustomerCode: null,
                ApplyRangeUpperCustomerCode: null,
                ApplyRangeSecondaryCustomers: '0',


                ApplyRangeLowerCustomerName: '',
                ApplyRangeUpperCustomerName: '',

                ItemGroupList: '',
                StorageList: '',
                ItemTagList: '',
                CustTagList: '',
                PosList: '',
                LowerSelectionDate: null,
                UpperSelectionDate: null,
                IsActive: '-1',
                TagRelation: '1',
                SortField: 'code',
                ShowPricesWithTax: 1, // bool

                IsGrouped: 0,
                mOnlyDefault: 0,

                itemCodeList: '',
                ApplyItemCodeList: 0,
                isDocDetailed: 0,
                mViewStock: 0,

                _withSendingDocs: '1',

                SupplierList: '',
                CustomerList: '',

                IncludeConnectedItems: '0',

                reportName: "reports/salesTagsDetailed.pdf",
            }
        };

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        //if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
        //    props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        //}
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericMultiSelectorCustomerTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorCustomerTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemGroup] === undefined) { //////
            props.data[TableIDs.genericSelectorItemGroup] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) { //////
            props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        }

        //if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
        //    props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        //}

        this.api = {
            selector_pos: "list_pos",
            //selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            // selectorMulti_custtag: "get_customer_tags_list",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag);
        // this.props.dataActions.genericMultiSelectorRefreshDataset(
        //     TableIDs.genericMultiSelectorCustomerTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_custtag);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes);//
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group); //
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier);
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetchData,
            args: [this.props.user.companyCode, this.props.user.token, { script: "data_get", params: { dataObject: "customerList" } }, "customerList", { loading: "", failed: "Error loading", success: "" }, false, { action: null, args: [] }, null
            ]
        });
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            // params = this.getFullSelector(params);
            params = {
                ...this.getFullSelector(params),
                reportName: params.reportName === "reports/salesTagsDetailed.xls" ?
                    "reports/salesTagsDetailedExcel.xls"
                    : params.reportName === "reports/salesDocsDetailed.xls" ?
                        "reports/salesDocsDetailedExcel.xls"
                        : params.reportName === "reports/salesStoragesDetailed.xls" ?
                            "reports/salesStoragesDetailedExcel.xls"
                            : params.reportName,
                REPORT_LOCALE: this.getText(101)
            }

            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params.PosList === '') params = { ...params, PosList: fullPosList }
        if (params.StorageList === '') params = { ...params, StorageList: fullStoragesList }
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10201);
        let posList = this.props.data[TableIDs.genericSelectorPos];
        let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (
            posList && posList.dataset[0]
            &&
            storagesList && storagesList.dataset[0]
            && !this.state.loading
        ) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams && this.props.ui.favoriteParams) {
            let reportName = this.props.ui.favoriteParams.reportName.split('.');
            let type = reportName[1];
            let RequestedReport = 'Tags';
            if (reportName[0].indexOf('Suppliers') > -1) RequestedReport = 'Suppliers';
            if (reportName[0].indexOf('Customers') > -1) RequestedReport = 'Customers';
            if (reportName[0].indexOf('Employees') > -1) RequestedReport = 'Employees';
            if (reportName[0].indexOf('Storages') > -1) RequestedReport = 'Storages';
            if (reportName[0].indexOf('Docs') > -1) RequestedReport = 'Docs';

            this.setState({ type, RequestedReport }, () => {
                this.setReportParameters("SalesDetailed", this.props.ui.favoriteParams)
            })
        }

    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        //this.props.ActionQueue.addToQueue({
        //    action: this.props.dataActions.genericSelectorRefreshDataset,
        //    args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        //});
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag]
        });
        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericMultiSelectorRefreshDataset,
        //     args: [TableIDs.genericMultiSelectorCustomerTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_custtag]
        // });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group]
        });
        //this.props.ActionQueue.addToQueue({
        //    action: this.props.dataActions.genericSelectorRefreshDataset,
        //    args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier]
        //});
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.SalesDetailed.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.SalesDetailed.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("SalesDetailed", { [field]: value })
        this.setState({ [field]: value, });
    }

    onStartChange = (value) => {
        this.onChange('LowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.onChange('UpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/
    createCheckBoxSelector = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("SalesDetailed", { [field]: data });
                    else
                        this.setReportParameters("SalesDetailed", { [field]: '' });
                }} />);
    }

    createCheckBoxLabel = (field, label, content, style) => {
        let checked = this.state.SalesDetailed[field] == 1;
        let onChange = (value) => {
            if (field === "isDocDetailed" && !value.target.checked && this.state.SalesDetailed.SortField != "code" && this.state.SalesDetailed.SortField != "name") {
                this.setReportParameters("SalesDetailed", { isDocDetailed: 0, SortField: "code" })
            } else {
                this.setReportParameters("SalesDetailed", { [field]: value.target.checked ? 1 : 0 })
            }
        }
        return (<FormItem style={style}>
            <Checkbox checked={checked} onChange={onChange}>{this.getText(label)}</Checkbox>
            {checked ? content : ""}
        </FormItem>)
    }

    createInput = (field) => {
        return (<Input
            value={this.state.SalesDetailed[field]}
            onChange={(value) => this.setReportParameters("SalesDetailed", { [field]: value.target.value })}
            style={{ marginLeft: '4px' }} />)
    }

    createInputNumber = (field) => {
        return (<InputNumber
            value={this.state.SalesDetailed[field]}
            onChange={(value) => this.setReportParameters("SalesDetailed", { [field]: value })}
            style={{ marginLeft: '4px' }} />)
    }

    createItemPicker = (field, nameField) => {
        return (<SelectorItemClassic
            idSelector={field}
            user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui}
            dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
            valueId={this.state.SalesDetailed[field]} valueName={this.state[nameField]}
            onChange={(code, name) => {
                this.setState({ [nameField]: name }, () => {
                    this.setReportParameters("SalesDetailed", { [field]: code })
                })
            }}
        />)
    }

    render() {
        const { SalesDetailed, endOpen, RequestedReport } = this.state;
        const { LowerSelectionDate, LowerSelectionTime,
            UpperSelectionDate, UpperSelectionTime } = SalesDetailed;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //const { SalesDetailed } = this.state;
        //const isMinPercShownStyle = { display: (SalesDetailed.RequestedReport === 5 || SalesDetailed.RequestedReport === 6) ? 'block' : 'none' }
        const styleDisplayCustomersReport = { display: (RequestedReport === 'Customers') ? 'block' : 'none' }
        const styleDisplayTagsReport = { display: (RequestedReport === 'Tags') ? 'block' : 'none' }
        const styleDisplayStoragesReport = { display: (RequestedReport === 'Storages') ? 'block' : 'none' }
        const styleDisplayNotDocsReport = { display: (RequestedReport !== 'Docs') ? 'block' : 'none' }
        const styleDisplayNotDocsOrCustReport = { display: (RequestedReport !== 'Docs' && RequestedReport !== 'Customers') ? 'block' : 'none' }

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listItemTags = Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", []).map(
            (node: TagObj, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: string, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )

        // let listCustTags = Jax.get(this.props.data, TableIDs.genericMultiSelectorCustomerTags + ".dataset", []).map(
        //     (node: TagObj, index) => {
        //         return (
        //             <OptGroup label={node.type.text} key={"type_" + node.type.id}>
        //                 {node.tags.map((item: string, index) => {
        //                     return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
        //                 })}
        //             </OptGroup>
        //         )
        //     }
        // )

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let sortFieldOptions = [
            { id: "code", text: 12118 },
            { id: "name", text: 12119 },
            { id: "doc", text: 18825 },
            { id: "date", text: 18826 },
        ]

        if (this.state.SalesDetailed.isDocDetailed != 1 && this.state.RequestedReport != "Docs") {
            sortFieldOptions = sortFieldOptions.slice(0, 2)
        }

        let customerPickerLabel = this.getText(12121);

        let custValLower = (this.state.SalesDetailed.ApplyRangeLowerCustomerCode) ?
            this.state.SalesDetailed.ApplyRangeLowerCustomerCode + " - " + this.state./*SalesDetailed.*/ApplyRangeLowerCustomerName : customerPickerLabel;
        let custValUpper = (this.state.SalesDetailed.ApplyRangeUpperCustomerCode) ?
            this.state.SalesDetailed.ApplyRangeUpperCustomerCode + " - " + this.state./*SalesDetailed.*/ApplyRangeUpperCustomerName : customerPickerLabel;

        let mobile = window.innerWidth > 600 ? false : true;

        let reportParams = { ...this.state.SalesDetailed }
        reportParams = {
            ...reportParams,
            // reportName: reportParams.reportName === "reports/salesTagsDetailed.xls" ?
            //     "reports/salesTagsDetailedExcel.xls"
            //     : reportParams.reportName === "reports/salesDocsDetailed.xls" ?
            //         "reports/salesDocsDetailedExcel.xls"
            //         : reportParams.reportName === "reports/salesStoragesDetailed.xls" ?
            //             "reports/salesStoragesDetailedExcel.xls"
            //             : reportParams.reportName,
            ApplyRangeLowerCustomerCode: (reportParams.ApplyRangeLowerCustomerCode) ? encodeURIComponent(reportParams.ApplyRangeLowerCustomerCode) : null,
            ApplyRangeUpperCustomerName: (reportParams.ApplyRangeLowerCustomerCode) ? encodeURIComponent(reportParams.ApplyRangeLowerCustomerCode) : null
        }

        let encodeURIFields = [
            'ApplyRangeLowerItemCode',
            'ApplyRangeUpperItemCode',
            'ApplyRangeLowerItemName',
            'ApplyRangeUpperItemName',
        ]

        if (this.state.SalesDetailed.TagRelation == '2') encodeURIFields.push("ItemTagList")

        return (
            <div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(12093)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '80%' }}
                    bodyStyle={{ padding: 16 }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>


                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '3', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <FormItem label={this.getText(12094)}>
                            <Select key={"RequestedReport"} style={{ width: 250 }}
                                //size={"large"}
                                //className={""}
                                //combobox={null}
                                value={this.state.RequestedReport}
                                onChange={
                                    (value) => {
                                        //console.log("request pick: ", value);
                                        //this.setReportParameters("SalesDetailed", { RequestedReport: value });
                                        //let s = {...this.state}
                                        //s.RequestedReport = value;
                                        //this.setState(s);
                                        this.setState({ RequestedReport: value }, () => {

                                            this.setReportParameters("SalesDetailed", {
                                                reportName: "reports/sales" + value + "Detailed." + this.state.type
                                            });
                                        })
                                    }
                                }
                            >
                                <Option key={1} value={"Tags"}>{this.getText(12122)}</Option>
                                <Option key={2} value={"Suppliers"}>{this.getText(12123)}</Option>
                                <Option key={3} value={"Customers"}>{this.getText(12124)}</Option>
                                <Option key={4} value={"Employees"}>{this.getText(13354)}</Option>
                                <Option key={5} value={"Storages"}>{this.getText(15966)}</Option>
                                <Option key={6} value={"Docs"}>{this.getText(51814)}</Option>
                            </Select>
                        </FormItem>

                        {this.createCheckBoxLabel("IsGrouped", 12095, "", styleDisplayCustomersReport)}
                        {this.createCheckBoxLabel("mOnlyDefault", 15369, "", styleDisplayTagsReport)}
                        {this.createCheckBoxLabel("isDocDetailed", 15453, "", styleDisplayNotDocsOrCustReport)}
                        {this.createCheckBoxLabel("mViewStock", 15967, "", styleDisplayStoragesReport)}

                        <FormItem label={this.getText(12096)}>
                            <GenericSelectorPosStorages  {...this.props} multi
                                onChange={(value) => this.setReportParameters("SalesDetailed", { PosList: value.join(',') })}
                                value={(this.state.SalesDetailed.PosList === '') ? [] : this.state.SalesDetailed.PosList.split(',')}
                            />

                        </FormItem>


                        <FormItem label={this.getText(12098)}>

                            <GenericSelectorItemTagsWithRelation {...this.props} notApi
                                value={this.state.SalesDetailed.ItemTagList}
                                onChange={(value) => { this.setReportParameters("SalesDetailed", { ItemTagList: value }) }}
                                tagRelationValue={this.state.SalesDetailed.TagRelation}
                                tagRelationOnChange={(value) => {
                                    let ItemTagList = this.state.SalesDetailed.ItemTagList;
                                    let from = this.state.SalesDetailed.TagRelation;
                                    let to = value.target.value;
                                    let oldJson = ItemTagList.indexOf("{") > -1 ? JSON.parse(ItemTagList) : {}
                                    if (from == "2") ItemTagList = oldJson.tagList1 ?? ""
                                    if (to == "2") ItemTagList = JSON.stringify({ tagList1: ItemTagList, tagList2: "", tagRelation1: "1", tagRelation2: "1", betwentRelation: "1" })
                                    this.setReportParameters("SalesDetailed", { TagRelation: to, ItemTagList })
                                }}
                            />
                        </FormItem>

                        {this.state.RequestedReport == "Customers" ?
                            <FormItem label={this.getText(14169)} >
                                <GenericSelectorCustTags {...this.props} multi
                                    onChange={(value) => this.setReportParameters("SalesDetailed", { CustTagList: value.sort().join(',') })}
                                    value={(this.state.SalesDetailed.CustTagList === '') ? [] : this.state.SalesDetailed.CustTagList.split(',')}
                                />
                            </FormItem>
                            : ""}

                        <FormItem label={this.getText(12099)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                            <DatePicker
                                disabledDate={d => disabledDatePicker(d, null, SalesDetailed.UpperSelectionDate)}
                                // disabledDate={this.disabledStartDate}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                value={SalesDetailed.LowerSelectionDate ? moment(SalesDetailed.LowerSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12114)}
                                onChange={this.onStartChange}
                            //onOpenChange={this.handleStartOpenChange}
                            />

                        </FormItem>
                        <FormItem label={this.getText(12100)} /*style={isEndDateShownStyle}*/>
                            <DatePicker
                                disabledDate={d => disabledDatePicker(d, SalesDetailed.LowerSelectionDate, null)}
                                //  disabledDate={this.disabledEndDate}
                                // showTime
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                //format="D/M/YY"
                                //value={ToDate ? moment(ToDate, "YYYY-MM-DD") : null}
                                value={SalesDetailed.UpperSelectionDate ? moment(SalesDetailed.UpperSelectionDate, "YYYY-MM-DD") : null}
                                placeholder={this.getText(12115)}
                                onChange={this.onEndChange}
                            //open={endOpen}
                            //onOpenChange={this.handleEndOpenChange}
                            />
                        </FormItem>

                        <FormItem>
                            <CheckboxIncludeSendingDocs
                                value={this.state.SalesDetailed._withSendingDocs}
                                onChange={e => { this.setReportParameters("SalesDetailed", { _withSendingDocs: e }) }}
                            />
                        </FormItem>

                        <Form.Item label={this.getText(15452)}>
                            <SelectorItemsMulti
                                {...this.props}
                                fieldName={"itemCodeList"}
                                onChange={(value) => {
                                    this.setReportParameters("SalesDetailed", {
                                        ApplyItemCodeList: value.length ? "1" : "0",
                                        itemCodeList: value.map(x => x.mCode).join(',')
                                    })
                                }} />
                        </Form.Item>

                        {this.state.RequestedReport == 'Employees' ?
                            <Form.Item>
                                <Checkbox
                                    checked={this.state.SalesDetailed.IncludeConnectedItems == '1'}
                                    onChange={e => {
                                        this.setReportParameters("SalesDetailed", { IncludeConnectedItems: e.target.checked ? '1' : '0' })
                                    }}>{this.getText(19556)}</Checkbox>
                            </Form.Item>
                            : ""}

                        <Form.Item label={this.getText(19331)}>
                            <GenericSelectorSuppliers
                                {...this.props}
                                multi
                                onChange={(value) => this.setReportParameters("SalesDetailed", { SupplierList: value.sort().join(',') })}
                                value={this.state.SalesDetailed.SupplierList ? this.state.SalesDetailed.SupplierList.split(',') : []}
                            />
                        </Form.Item>
                        <Form.Item label={this.getText(19332)}>
                            <GenericSelectorCustomers
                                {...this.props}
                                multi
                                onChange={(value) => this.setReportParameters("SalesDetailed", { CustomerList: value.sort().join(',') })}
                                value={this.state.SalesDetailed.CustomerList ? this.state.SalesDetailed.CustomerList.split(',') : []}
                                isGroupFromConnected={this.state.SalesDetailed.ApplyRangeSecondaryCustomers}
                            />
                            <Radio.Group
                                value={this.state.SalesDetailed.ApplyRangeSecondaryCustomers}
                                onChange={(value) => {
                                    this.setReportParameters("SalesDetailed", {
                                        ApplyRangeSecondaryCustomers: value.target.value,
                                    })
                                }}
                            >
                                <Radio value={'0'}>{this.getText(19084)}</Radio>
                                <Radio value={'1'}>{this.getText(19085)}</Radio>
                                <Radio value={'2'}>{this.getText(19086)}</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <FormItem label={this.getText(12104)}>
                            <Select key={"IsActive"} style={{ width: 250 }}
                                value={SalesDetailed.IsActive}
                                onChange={(value) => { this.setReportParameters("SalesDetailed", { IsActive: value }); }}
                            //value={}
                            >
                                <Option key={1} value={'-1'}>-</Option>
                                <Option key={2} value={'1'}>{this.getText(12116)}</Option>
                                <Option key={3} value={'0'}>{this.getText(12117)}</Option>
                            </Select>

                        </FormItem>
                        <FormItem label={this.getText(12105)}>
                            <Select key={"SortField"} style={{ width: 250 }}
                                value={SalesDetailed.SortField}
                                // defaultValue={'code'}
                                onChange={(value) => { this.setReportParameters("SalesDetailed", { SortField: value }); }}
                            //value={}
                            >
                                {sortFieldOptions.map(x => (<Option key={x.id} value={x.id}>{this.getText(x.text)}</Option>))}
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(12106)} style={styleDisplayNotDocsReport}>
                            <Switch checkedChildren={this.getText(12116)} unCheckedChildren={this.getText(12117)}
                                checked={SalesDetailed.ShowPricesWithTax == 1}
                                onChange={(value) => this.setReportParameters("SalesDetailed", { ShowPricesWithTax: (value === true) ? 1 : 0 })} />
                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={reportParams}
                            storagesField={'StorageList'}
                            tagsField={'ItemTagList'}
                            posField={'PosList'}
                            encodeURIFields={encodeURIFields}
                            datesToFavorites={[
                                { field: 'LowerSelectionDate', label: this.getText(12099) },
                                { field: 'UpperSelectionDate', label: this.getText(12100) },
                            ]}
                            favoriteSave
                            xlsReportName={reportParams.reportName.split(".")[0] + "Excel.xls"}

                        />

                        {/* <FormItem key={"reportName"} label={this.getText(12107)}>
                            <Select style={{ width: 250 }}
                                //size={"large"}
                                className={"reports/SalesDetailed.pdf"}
                                //combobox={null}
                                defaultValue={"pdf"}
                                onChange={(value) => {
                                    //const type = this.state.SalesDetailed.mRequestedReport;
                                    //console.log(type);
                                    //const str = (type === 0) ? '' : type.toString();
                                    //console.log(value,str);
                                    //this.setReportParameters("SalesDetailed", { reportName: "reports/SalesDetailed" + str + ".pdf"})}
                                    this.setState({ type: value }, () => {
                                        this.setReportParameters("SalesDetailed", { reportName: this.state.SalesDetailed.reportName.split('.')[0] + "." + value });
                                    });

                                }}
                            >
                                <Option key={"pdf"}>{this.getText(12108)}</Option>
                                <Option key={"xls"}>{this.getText(12109)}</Option>
                                <Option key={"docx"}>{this.getText(12110)}</Option>
                            </Select>
                        </FormItem>
                        <FormItem key={"submit"}>
                            <Button type={"primary"} onClick={() => {
                                let type = this.state.RequestedReport;
                                //this.setReportParameters("SalesDetailed", { reportName: "reports/sales" + type + "Detailed." + this.state.type });

                                this.setReportParameters("SalesDetailed", {
                                    ApplyRangeLowerCustomerCode: (this.state.SalesDetailed.ApplyRangeLowerCustomerCode)
                                        ? encodeURIComponent(this.state.SalesDetailed.ApplyRangeLowerCustomerCode) : null,
                                    ApplyRangeUpperCustomerName: (this.state.SalesDetailed.ApplyRangeLowerCustomerCode)
                                        ? encodeURIComponent(this.state.SalesDetailed.ApplyRangeLowerCustomerCode) : null
                                })
                                this.generateReport.apply(this, ["SalesDetailed"]);
                            }}>{this.getText(12111)}</Button>
                        </FormItem> */}
                    </Form>
                </Card>
            </div>
        );
    }
}
export default SalesDetailedReport;
